{
  "pageProps": {
    "category": {
      "id": 2,
      "count": 61,
      "description": "Informasi terkini mengenai COVID-19. 100% faktual, dari sumber-sumber terpercaya.",
      "name": "Artikel",
      "slug": "artikel"
    },
    "posts": [
      {
        "id": 2192,
        "date_gmt": "2021-10-05T14:16:46",
        "modified_gmt": "2021-10-05T14:22:35",
        "slug": "pentingnya-vaksinasi-untuk-sekolah-tatap-muka",
        "type": "post",
        "title": {
          "rendered": "Pentingnya Vaksinasi Untuk Sekolah Tatap Muka"
        },
        "excerpt": {
          "rendered": "<p>Pembukaan sekolah sudah mulai berjalan. Namun pertanyaannya yang sama juga masih bergaung, “Amankah?”</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 2135,
        "date_gmt": "2021-07-24T03:35:57",
        "modified_gmt": "2021-07-24T03:36:26",
        "slug": "varian-delta-yang-perlu-anda-ketahui",
        "type": "post",
        "title": {
          "rendered": "Varian Delta: Yang Perlu Anda Ketahui"
        },
        "excerpt": {
          "rendered": "<p>Simak rangkuman tentang varian delta yang perlu anda ketahui</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 2066,
        "date_gmt": "2021-07-16T12:15:49",
        "modified_gmt": "2021-07-21T01:03:43",
        "slug": "panduan-menyambut-ibadah-idul-adha-2021",
        "type": "post",
        "title": {
          "rendered": "Panduan Menyambut Ibadah Idul Adha 2021 :"
        },
        "excerpt": {
          "rendered": "<p>Simak panduan berikut untuk menyambut ibadah Idul Adha 2021 yang dikeluarkan oleh Kementrian Agama</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1964,
        "date_gmt": "2021-06-19T07:04:06",
        "modified_gmt": "2021-06-19T07:49:26",
        "slug": "10-cara-indonesia-tangguh-melawan-covid-19",
        "type": "post",
        "title": {
          "rendered": "10 Cara Indonesia Tangguh Melawan COVID-19"
        },
        "excerpt": {
          "rendered": "<p>Wabah COVID19 kembali memuncak. Naiknya mobilitas masyarakat, mengendornya prosedur dan ketaatan akan protokol kesehatan di semua lini, dan masuknya varian Alfa dan Delta yang lebih cepat penyebarannya menyebabkan kasus positif harian terus meninggi. </p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1960,
        "date_gmt": "2021-06-18T14:14:17",
        "modified_gmt": "2021-06-18T14:15:06",
        "slug": "e-participation-against-covid-19-case-of-kawalcovid-19-id",
        "type": "post",
        "title": {
          "rendered": "E-Participation against COVID-19: Case of KawalCOVID-19.id"
        },
        "excerpt": {
          "rendered": "",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1956,
        "date_gmt": "2021-06-14T09:22:43",
        "modified_gmt": "2021-06-14T09:23:42",
        "slug": "vaksin-untuk-penyintas-covid-19-perlu-atau-tidak",
        "type": "post",
        "title": {
          "rendered": "Vaksin untuk Penyintas COVID-19, Perlu atau Tidak?"
        },
        "excerpt": {
          "rendered": "<p>Vaksinasi untuk penyintas COVID-19 apakah diperlukan? Simak bahasan berikut ini.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1931,
        "date_gmt": "2021-05-31T02:44:59",
        "modified_gmt": "2021-07-03T09:34:24",
        "slug": "cara-isolasi-mandiri-yang-benar",
        "type": "post",
        "title": {
          "rendered": "Cara Isolasi Mandiri yang Benar"
        },
        "excerpt": {
          "rendered": "<p>Tim KawalCOVID-19 berbincang dengan dr. Sayuri Suwandi, SpPD dan dr. Dirga Sakti Rambe, SpPD, untuk menjawab pertanyaan seputar cara isolasi mandiri (isoman) yang benar. Berikut ringkasan dari dua sesi Instagram Live yang bisa dilihat di sini.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1928,
        "date_gmt": "2021-05-12T15:22:00",
        "modified_gmt": "2021-05-12T15:24:39",
        "slug": "mengenal-dan-mencegah-varian-baru-virus-sars-cov-2",
        "type": "post",
        "title": {
          "rendered": "Mengenal dan Mencegah Varian Baru Virus SARS-CoV-2"
        },
        "excerpt": {
          "rendered": "<p>Virus SARS-CoV-2, yang menjadi penyebab munculnya COVID-19, telah bermutasi dan menyebar ke seluruh dunia. Mutasi menghasilkan beberapa varian baru, satu di antaranya ialah B.1.1.7 yang terdeteksi pertama kali di Inggris Raya pada September 2020.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1913,
        "date_gmt": "2021-04-29T01:25:03",
        "modified_gmt": "2021-04-29T01:25:42",
        "slug": "ramadhan-2021-tetap-waspada-menjaga-3m-dan-bersiap-untuk-tidak-mudik",
        "type": "post",
        "title": {
          "rendered": "Ramadhan 2021: Tetap Waspada, Menjaga 3M dan Bersiap untuk Tidak Mudik."
        },
        "excerpt": {
          "rendered": "<p>Refleksi di Ramadhan 2021 terkait kondisi pandemi COVID-19</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1906,
        "date_gmt": "2021-04-19T00:54:15",
        "modified_gmt": "2021-04-27T01:32:31",
        "slug": "gangguan-stres-pascatrauma-pada-penyintas-covid-19",
        "type": "post",
        "title": {
          "rendered": "Gangguan Stres Pascatrauma pada Penyintas COVID-19"
        },
        "excerpt": {
          "rendered": "<p>Dinyatakan sembuh dari COVID-19 tidak serta merta membuat para penyintas kembali hidup normal. Ada risiko penurunan kondisi fisik, dan juga kesehatan mental. Artikel ini membahas gangguan stres pasca trauma pada penyintas COVID-19</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1904,
        "date_gmt": "2021-04-19T00:53:13",
        "modified_gmt": "2021-04-19T01:01:02",
        "slug": "satu-tahun-covid-19-di-indonesia-waspadai-pandemic-fatigue",
        "type": "post",
        "title": {
          "rendered": "Satu Tahun COVID-19 di Indonesia, Waspadai Pandemic Fatigue!"
        },
        "excerpt": {
          "rendered": "<p>Berbagai adaptasi terhadap kebiasaan baru dan pembatasan yang terjadi selama satu tahun ini telah membuat sebagian besar dari kita merasa kelelahan. Artikel ini akan membahas tentang kelelahan pandemi (pandemic fatigue), dampak yang dapat ditimbulkan, dan kiat yang dapat kita lakukan bersama untuk mengatasinya.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1902,
        "date_gmt": "2021-04-19T00:51:49",
        "modified_gmt": "2021-04-19T01:00:29",
        "slug": "kipi-atau-reaksi-kecemasan-terkait-vaksinasi",
        "type": "post",
        "title": {
          "rendered": "KIPI atau Reaksi Kecemasan Terkait Vaksinasi?"
        },
        "excerpt": {
          "rendered": "<p>Hendaknya kita tidak perlu terlalu panik dan terburu-buru melabeli setiap keluhan yang muncul ketika atau setelah vaksinasi sebagai efek samping vaksin atau efek prosedur vaksinasi. Bisa jadi, itu adalah reaksi kecemasan terkait vaksinasi. Artikel ini menjelaskan berbagai jenis KIPI, cara membedakan, dan kiat mengatasinya baik dari sisi tenaga kesehatan yang bertugas maupun masyarakat penerima vaksin.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1879,
        "date_gmt": "2021-04-15T02:48:10",
        "modified_gmt": "2021-06-25T09:40:22",
        "slug": "satgas-kesehatan-di-sekolah-untuk-apa",
        "type": "post",
        "title": {
          "rendered": "Satgas Kesehatan di Sekolah, Untuk Apa?"
        },
        "excerpt": {
          "rendered": "<p>Per 24 Februari 2021, sudah ada wacana membuka kembali sekolah setelah vaksinasi guru rampung di bulan Juni. Di medio 2021, belum tentu pandemi sudah terkendali, apalagi berakhir. Karenanya, selain harus bersiap untuk mencegah penularan, sekolah juga harus bersiap diri untuk menangani adanya penularan di sekolah, sehingga tidak bingung dan menangani dengan serabutan ketika ada kasus. [&hellip;]</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1828,
        "date_gmt": "2021-04-05T14:44:30",
        "modified_gmt": "2021-04-05T14:44:51",
        "slug": "benarkah-covid-19-memicu-penyakit-ginjal",
        "type": "post",
        "title": {
          "rendered": "Benarkah COVID-19 Memicu Penyakit Ginjal?"
        },
        "excerpt": {
          "rendered": "<p>COVID-19 menimbulkan berbagai komplikasi penyakit, salah satunya penyakit ginjal. Beberapa penelitian menunjukkan bahwa pasien COVID-19 dapat menderita sakit ginjal, spesifiknya cedera ginjal akut. </p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1818,
        "date_gmt": "2021-04-01T03:05:23",
        "modified_gmt": "2021-07-03T09:03:10",
        "slug": "interaksi-guru-murid-yang-minim-risiko",
        "type": "post",
        "title": {
          "rendered": "Interaksi Guru-Murid Yang Minim Risiko"
        },
        "excerpt": {
          "rendered": "<p>Per 24 Februari 2021, sudah ada wacana bahwa pemerintah akan membuka sekolah setelah vaksinasi guru rampung di bulan Juni. Di medio 2021, belum tentu pandemi terkendali, apalagi berakhir. Karenanya, penting bagi pendidik untuk melengkapi diri dengan cara aman melakukan berinteraksi dengan anak didik.&nbsp; COVID-19 menyebar lewat droplet seperti air liur dan ingus, dua hal yang [&hellip;]</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1669,
        "date_gmt": "2021-03-18T14:44:28",
        "modified_gmt": "2021-04-05T14:51:50",
        "slug": "tb-dan-covid-19",
        "type": "post",
        "title": {
          "rendered": "TB dan COVID-19"
        },
        "excerpt": {
          "rendered": "<p>Penulis: Nabila Hana Wardhani Tuberkulosis dan COVID-19 memiliki gejala yang serupa tapi tak sama seperti adanya batuk, demam dan sesak napas. Penyebab dan pengobatan dari kedua penyakit tersebut juga berbeda. Apabila keduanya diidap oleh seseorang, perkiraan perjalanan COVID-19 seseorang akan menjadi lebih buruk. Selain itu pandemi membawa problematika baru bagi TB, seperti melonjaknya kasus resistensi [&hellip;]</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1660,
        "date_gmt": "2021-02-27T02:28:10",
        "modified_gmt": "2021-04-05T14:53:18",
        "slug": "vaksin-covid-19",
        "type": "post",
        "title": {
          "rendered": "Vaksin COVID-19"
        },
        "excerpt": {
          "rendered": "<p>Penjelasan singkat tentang vaksin COVID-19</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1645,
        "date_gmt": "2021-02-20T05:39:49",
        "modified_gmt": "2021-07-03T09:03:19",
        "slug": "seri-covid-19-artikel-2-sebelum-buka-sekolah-apa-yang-harus-dikomunikasikan",
        "type": "post",
        "title": {
          "rendered": "Sebelum Buka Sekolah, Apa yang Harus Dikomunikasikan?"
        },
        "excerpt": {
          "rendered": "<p>Kebanyakan sekolah mengerti bahwa ada protokol kesehatan yang harus disiapkan sebelum membuka sekolah di masa pandemi. Tapi dari berbagai input yang disampaikan oleh guru, murid, orang tua maupun administrator sekolah, tidak ada pedoman bagaimana mengkomunikasikan pembukaan sekolah dan perilaku seperti apa yang sekolah minta dari para guru, siswa dan orang tua murid.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1642,
        "date_gmt": "2021-02-20T05:35:56",
        "modified_gmt": "2021-04-07T10:24:29",
        "slug": "infrastruktur-apa-yang-perlu-disiapkan-sekolah-new-normal",
        "type": "post",
        "title": {
          "rendered": "Infrastruktur Apa yang Perlu Disiapkan Sekolah New Normal?"
        },
        "excerpt": {
          "rendered": "<p>Di satu titik, sekolah akan tatap muka lagi. Berapa lama lagi, kita belum tahu. Tapi sekolah perlu menyiapkan infrastruktur new normal mulai sekarang agar ketika nanti sekolah dibuka, semua pihak sudah mengerti apa yang harus dilakukan.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1612,
        "date_gmt": "2021-01-14T02:21:49",
        "modified_gmt": "2021-03-02T04:52:49",
        "slug": "pertimbangan-untuk-langkah-langkah-kesehatan-masyarakat-terkait-sekolah-dalam-konteks-covid-19",
        "type": "post",
        "title": {
          "rendered": "Pertimbangan untuk Langkah-Langkah Kesehatan Masyarakat Terkait Sekolah dalam Konteks COVID-19"
        },
        "excerpt": {
          "rendered": "",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 2206,
        "date_gmt": "2020-12-29T13:34:00",
        "modified_gmt": "2022-02-07T23:32:45",
        "slug": "laporan-donasi-dana-tahap-kedua-infrastruktur-kawalcovid19-id-di-azure-cloud",
        "type": "post",
        "title": {
          "rendered": "Laporan Donasi Dana Tahap Kedua Infrastruktur KawalCOVID19.id di Azure Cloud"
        },
        "excerpt": {
          "rendered": "<p>Tim KawalCOVID19.id menerima Donasi Tahap Kedua dari Program IEG Peduli COVID-19 Yayasan Indika. Donasi digunakan seutuhnya untuk pendanaan infrastruktur teknologi website KawalCOVID19.id yang menggunakan Azure Cloud. Rincian data donasi terlampir dan dapat diunduh (download) di sini.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1558,
        "date_gmt": "2020-12-22T15:15:43",
        "modified_gmt": "2021-03-02T04:36:10",
        "slug": "pandemic-fatigue",
        "type": "post",
        "title": {
          "rendered": "Pandemic Fatigue"
        },
        "excerpt": {
          "rendered": "<p>Bosan dengar masalah Covid? Inilah kenapa anda mengalami kelelahan pandemi atau pandemic fatigue</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1541,
        "date_gmt": "2020-11-26T02:03:54",
        "modified_gmt": "2021-03-02T04:36:21",
        "slug": "penapisan-covid-19-bagi-petugas-pilkada",
        "type": "post",
        "title": {
          "rendered": "Penapisan COVID-19 bagi Petugas Pilkada"
        },
        "excerpt": {
          "rendered": "<p>Tes antibodi saja tidak bisa diandalkan untuk mencegah penularan COVID-19 selama Pilkada. Artikel ini menjabarkan strategi pemilahan kelompok sesuai hasil tes PCR dan langkah selanjutnya yang perlu diambil.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 2201,
        "date_gmt": "2020-09-22T15:37:00",
        "modified_gmt": "2022-02-07T23:31:52",
        "slug": "laporan-donasi-dana-tahap-pertama-infrastruktur-kawalcovid19-id-di-azure-cloud",
        "type": "post",
        "title": {
          "rendered": "Laporan Donasi Dana Tahap Pertama Infrastruktur KawalCOVID19.id di Azure Cloud"
        },
        "excerpt": {
          "rendered": "<p>Tim KawalCOVID19.id menerima Donasi Tahap Pertama dari Program IEG Peduli COVID-19 Yayasan Indika. Donasi digunakan seutuhnya untuk pendanaan infrastruktur teknologi website KawalCOVID19.id yang menggunakan Azure Cloud. Rincian data donasi terlampir dan bisa diunduh (download) di sini.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1466,
        "date_gmt": "2020-09-17T05:27:39",
        "modified_gmt": "2021-03-02T04:36:37",
        "slug": "rendahnya-pelacakan-kontak-dan-tingginya-tingkat-positivitas-tes-latar-belakang-dan-prospek-psbb-ii-dki-jakarta",
        "type": "post",
        "title": {
          "rendered": "Rendahnya Pelacakan Kontak dan Tingginya Tingkat Positivitas Tes: Latar Belakang dan Prospek PSBB II DKI Jakarta"
        },
        "excerpt": {
          "rendered": "<p>Kami menjabarkan tiga tren penting yang menjelaskan lonjakan kasus di DKI Jakarta, yakni (1) memburuknya pelacakan, (2) meningkatnya tingkat positivitas tes meskipun jumlah tesnya bertambah dan (3) inefisiensi tes mandiri. Rekomendasi kami bagi Pemprov DKI agar PSBB kedua berhasil mengendalikan wabah: 1) tingkatkan rasio lacak isolasi hingga 30 kontak erat per 1 kasus baru, 2) pekerjakan tim pelacak kontak khusus, 3) lampaui protokol revisi ke-5 dan haruskan semua kontak erat dites usap meskipun tidak bergejala ketika dikarantina di fasilitas terpusat, 4) kurangi tes mandiri yang tidak perlu dan 5) pertimbangkan pendekatan “stick and carrot”.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1361,
        "date_gmt": "2020-07-22T14:56:02",
        "modified_gmt": "2021-03-02T04:36:44",
        "slug": "serial-data-virus-corona-4-cara-memperbaiki-indeks-kewaspadaan",
        "type": "post",
        "title": {
          "rendered": "Serial Data Virus Corona 4: Cara Memperbaiki Indeks Kewaspadaan"
        },
        "excerpt": {
          "rendered": "<p>Serial data virus corona ke-4 ini menjelaskan bagaimana cara memperbaiki indeks kewaspadaan</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1330,
        "date_gmt": "2020-07-09T12:09:26",
        "modified_gmt": "2021-03-02T04:36:53",
        "slug": "serial-data-virus-korona-3-indeks-kewaspadaan",
        "type": "post",
        "title": {
          "rendered": "Serial Data Virus Korona 3: Indeks Kewaspadaan"
        },
        "excerpt": {
          "rendered": "<p>Serial data virus korona 3 ini merupakan kelanjutan dari serial data 1 dan 2 yang menggambarkan indeks kewaspadaan daerah pengidap positif COVID-19. Dengan adanya indeks kewaspadaan ini, dapat dapat terlihat gambaran relatif berapa besar kemungkinan terjadi transmisi COVID-19 di daerah domisili Anda.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1294,
        "date_gmt": "2020-07-04T14:02:35",
        "modified_gmt": "2021-03-02T04:37:01",
        "slug": "serial-data-virus-korona-2-rasio-lacak-isolasi-rli-dan-korelasinya-dengan-kematian-kumulatif",
        "type": "post",
        "title": {
          "rendered": "Serial Data Virus Korona 2: Rasio Lacak &#8211; Isolasi (RLI) dan Korelasinya dengan Kematian Kumulatif"
        },
        "excerpt": {
          "rendered": "<p>Serial data kedua ini merupakan kelanjutan dari serial data pertama yang memmbahas tentang rasio lacak-isolasi dan hubungannya dengan jumlah kematian</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1288,
        "date_gmt": "2020-07-04T14:00:18",
        "modified_gmt": "2021-03-02T04:37:11",
        "slug": "serial-data-virus-korona-1-3t-trace-test-treat",
        "type": "post",
        "title": {
          "rendered": "Serial Data Virus Korona 1: 3T (Trace, Test, Treat)"
        },
        "excerpt": {
          "rendered": "<p>Serial data pertama ini menyajikan bagaimana menurunkan penyebaran COVID-19  melalui 3T (Trace, Test, Treat)</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1284,
        "date_gmt": "2020-06-15T15:45:44",
        "modified_gmt": "2021-03-02T04:53:16",
        "slug": "mpasi-anak-dalam-kondisi-darurat",
        "type": "post",
        "title": {
          "rendered": "MPASI Anak dalam Kondisi Darurat"
        },
        "excerpt": {
          "rendered": "<p>Menjaga asupan gizi anak penting di tengah pandemi dan mudah dilakukan dengan memberikan Menu 4 Bintang.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1268,
        "date_gmt": "2020-05-15T10:11:37",
        "modified_gmt": "2021-03-16T17:30:39",
        "slug": "ramadan-psbb-dan-mcdonalds",
        "type": "post",
        "title": {
          "rendered": "Ramadan, PSBB dan McDonalds"
        },
        "excerpt": {
          "rendered": "<p>Berikut beberapa contoh kejadian yang dapat meningkatkan penyebaran virus korona.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1215,
        "date_gmt": "2020-05-03T12:38:09",
        "modified_gmt": "2021-03-02T04:45:12",
        "slug": "tips-pengolahan-makanan-dengan-penanak-nasi",
        "type": "post",
        "title": {
          "rendered": "Tips Pengolahan Makanan dengan Penanak Nasi"
        },
        "excerpt": {
          "rendered": "<p>Tips ini berisi cara memasak setiap kelompok bahan makanan menggunakan penanak nasi (rice cooker) untuk mempertahankan nilai gizi dengan menghindari pemanasan terlalu tinggi dalam waktu lama.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1183,
        "date_gmt": "2020-04-27T07:52:40",
        "modified_gmt": "2021-03-02T04:45:22",
        "slug": "rapid-test-atau-swab-test-apa-bedanya-mana-yang-lebih-baik",
        "type": "post",
        "title": {
          "rendered": "Rapid test atau swab test: Apa bedanya? Mana yang lebih baik?"
        },
        "excerpt": {
          "rendered": "<p>Perbandingan metode rapid test dan swab test dalam pendeteksian COVID-19.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1177,
        "date_gmt": "2020-04-27T07:33:49",
        "modified_gmt": "2021-03-02T04:53:36",
        "slug": "imunisasi-rutin-selama-pandemi-covid-19",
        "type": "post",
        "title": {
          "rendered": "Imunisasi Rutin Selama Pandemi COVID-19"
        },
        "excerpt": {
          "rendered": "<p>Panduan untuk imunisasi rutin selama adanya pandemi COVID-19.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1090,
        "date_gmt": "2020-04-17T12:36:06",
        "modified_gmt": "2021-03-02T04:46:02",
        "slug": "hoax-dan-misinformasi-seputar-covid-19-apa-yang-bisa-kita-lakukan",
        "type": "post",
        "title": {
          "rendered": "Hoax dan Misinformasi Seputar COVID-19; Apa yang Bisa Kita Lakukan?"
        },
        "excerpt": {
          "rendered": "<p>Perkembangan Terkini Seputar Hoax COVID-19</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1079,
        "date_gmt": "2020-04-15T05:47:19",
        "modified_gmt": "2021-03-02T04:53:52",
        "slug": "himbauan-untuk-vaksinasi",
        "type": "post",
        "title": {
          "rendered": "Himbauan untuk Vaksinasi"
        },
        "excerpt": {
          "rendered": "<p>Dokumen terkait himbauan untuk pelaksanaan vaksinasi anak selama pandemi COVID-19.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1021,
        "date_gmt": "2020-04-11T12:43:35",
        "modified_gmt": "2021-03-02T04:47:08",
        "slug": "apakah-penyemprotan-disinfektan-efektif-membunuh-sars-cov-2",
        "type": "post",
        "title": {
          "rendered": "Apakah Penyemprotan Disinfektan Efektif Membunuh SARS-COV-2?"
        },
        "excerpt": {
          "rendered": "<p>Beberapa hal yang harus diketahui tentang penyemprotan cairan disinfektan.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1006,
        "date_gmt": "2020-04-10T08:11:49",
        "modified_gmt": "2021-03-02T04:47:32",
        "slug": "cerita-dari-jerman-pengalaman-dua-wni-di-bochum-dan-frankfurt",
        "type": "post",
        "title": {
          "rendered": "Cerita dari Jerman: Pengalaman Dua WNI di Bochum dan Frankfurt"
        },
        "excerpt": {
          "rendered": "<p>Seorang pelajar dan seorang pekerja asal Indonesia yang tinggal di Jerman membagikan kisah penanganan pandemi oleh pemerintah Jerman</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 992,
        "date_gmt": "2020-04-10T08:07:59",
        "modified_gmt": "2021-03-02T04:47:39",
        "slug": "cerita-dari-taiwan-respons-tepat-taiwan-dalam-menghadapi-sars-cov-2-dan-covid-19",
        "type": "post",
        "title": {
          "rendered": "Cerita dari Taiwan: Respons Tepat Taiwan dalam Menghadapi SARS COV-2 dan COVID-19"
        },
        "excerpt": {
          "rendered": "<p>Taiwan disebut-sebut sebagai negara yang mengantisipasi penyebaran COVID-19 dengan baik sebelum wabah memburuk. Artikel ini merangkum hal-hal baik yang dilakukan oleh Taiwan dan yang dapat dijadikan contoh.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 972,
        "date_gmt": "2020-04-07T11:23:11",
        "modified_gmt": "2021-03-02T04:47:48",
        "slug": "cerita-dari-swedia-dont-try-this-at-home-jangan-terapkan-di-indonesia",
        "type": "post",
        "title": {
          "rendered": "Cerita dari Swedia: Don’t try this at home! (Jangan Terapkan di Indonesia!)"
        },
        "excerpt": {
          "rendered": "<p>Swedia dan Indonesia memiliki kondisi sosial dan budaya yang berbeda. Maka, kebijakan pemerintah Swedia tidak semerta-merta dapat diterapkan di Indonesia karena perbedaan konteks memerlukan penanganan yang berbeda pula.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 950,
        "date_gmt": "2020-04-05T07:22:31",
        "modified_gmt": "2021-03-02T04:47:59",
        "slug": "edukasi-saya-pengidap-asma-apa-yang-harus-saya-persiapkan",
        "type": "post",
        "title": {
          "rendered": "[EDUKASI] Saya pengidap asma. Apa yang harus saya persiapkan?"
        },
        "excerpt": {
          "rendered": "<p>Yuk simak beberapa saran yang harus Anda lakukan jika mengidap kondisi medis kronis.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 880,
        "date_gmt": "2020-04-03T05:37:16",
        "modified_gmt": "2021-03-02T04:48:08",
        "slug": "komunikasi-risiko-covid-19-untuk-fasilitas-pelayanan-kesehatan",
        "type": "post",
        "title": {
          "rendered": "Komunikasi Risiko COVID-19 untuk Fasilitas Pelayanan Kesehatan"
        },
        "excerpt": {
          "rendered": "<p>Materi ini memberikan gambaran langkah-langkah utama menjaga keselamatan dan kesehatan di tempat kerja untuk manajemen fasilitas pelayanan kesehatan dan tenaga kesehatan.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 843,
        "date_gmt": "2020-03-31T04:43:23",
        "modified_gmt": "2021-03-02T04:48:26",
        "slug": "dunia-dalam-berita-covid-19",
        "type": "post",
        "title": {
          "rendered": "Dunia dalam Berita COVID-19"
        },
        "excerpt": {
          "rendered": "<p>Rangkuman berita terkini seputar Covid-19, termasuk Fatwa MUI terkait pengurusan jenazah.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 800,
        "date_gmt": "2020-03-28T05:43:05",
        "modified_gmt": "2021-03-02T04:48:44",
        "slug": "informasi-terkini-28-maret-2020",
        "type": "post",
        "title": {
          "rendered": "Informasi terkini &#8211; 28 Maret 2020"
        },
        "excerpt": {
          "rendered": "<p>Monitor pemberitaan media per-harinya terkait covid-19</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 724,
        "date_gmt": "2020-03-21T18:24:53",
        "modified_gmt": "2021-03-02T04:48:54",
        "slug": "tips-hidup-sehat-agar-tubuh-lebih-tahan-terhadap-penyakit",
        "type": "post",
        "title": {
          "rendered": "Tips Hidup Sehat Agar Tubuh Lebih Tahan terhadap Penyakit"
        },
        "excerpt": {
          "rendered": "<p>Kekebalan tubuh memegang peran penting dalam menjaga tubuh dari penyakit.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 639,
        "date_gmt": "2020-03-20T13:18:03",
        "modified_gmt": "2021-03-02T04:49:15",
        "slug": "polemik-penggunaan-ibuprofen",
        "type": "post",
        "title": {
          "rendered": "Polemik penggunaan Ibuprofen"
        },
        "excerpt": {
          "rendered": "<p>Apakah ibuprofen memperburuk infeksi COVID-19? &#8211; Bagaimana dengan obat penawar rasa sakit yang lainnya?</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 721,
        "date_gmt": "2020-03-18T18:15:00",
        "modified_gmt": "2021-03-02T04:49:24",
        "slug": "penanganan-jenazah-yang-terduga-odp-kemungkinan-pdp-dan-terkonfirmasi-positif-terinfeksi-covid-19",
        "type": "post",
        "title": {
          "rendered": "Penanganan Jenazah yang Terduga (ODP) / Kemungkinan (PDP) dan Terkonfirmasi (Positif) Terinfeksi COVID-19"
        },
        "excerpt": {
          "rendered": "<p>Meskipun belum ada korban COVID-19, pemerintah Singapura sudah mempersiapkan panduan penanganan jenazah untuk pemakaman dan kremasi</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 546,
        "date_gmt": "2020-03-17T05:37:28",
        "modified_gmt": "2021-03-02T04:49:32",
        "slug": "coronavirus-covid-19-bagaimana-mempersiapkan-keluarga-anda",
        "type": "post",
        "title": {
          "rendered": "Coronavirus (COVID-19): Bagaimana Mempersiapkan Keluarga Anda?"
        },
        "excerpt": {
          "rendered": "<p>Beberapa hal yang dapat dipersiapkan untuk keluarga Anda dalam menghadapi COVID-19.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 510,
        "date_gmt": "2020-03-16T10:01:50",
        "modified_gmt": "2021-03-02T04:49:40",
        "slug": "dos-and-donts-dalam-social-distancing",
        "type": "post",
        "title": {
          "rendered": "Do&#8217;s and Don&#8217;ts dalam Social Distancing"
        },
        "excerpt": {
          "rendered": "<p>Kuncinya hanya satu: Apakah kondisi tubuh kita sehat dan apakah kita punya gejala sakit? </p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 463,
        "date_gmt": "2020-03-16T07:17:28",
        "modified_gmt": "2021-03-02T04:49:50",
        "slug": "protokol-isolasi-mandiri-home-quarantine",
        "type": "post",
        "title": {
          "rendered": "Protokol Isolasi Mandiri (Home Quarantine)"
        },
        "excerpt": {
          "rendered": "<p>Langkah-langkah yang dapat dilakukan ketika isolasi mandiri untuk mencegah penularan</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 466,
        "date_gmt": "2020-03-16T06:55:29",
        "modified_gmt": "2021-03-02T04:49:58",
        "slug": "tips-menghadapi-karantina-di-rumah",
        "type": "post",
        "title": {
          "rendered": "Tips Menghadapi Karantina di Rumah"
        },
        "excerpt": {
          "rendered": "<p>Karantina membantu memperlambat penyebaran infeksi. Siapkan sebuah Perencanaan Karantina Rumah</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 464,
        "date_gmt": "2020-03-16T06:48:58",
        "modified_gmt": "2021-03-02T04:50:06",
        "slug": "apa-beda-orang-dalam-pemantauan-odp-dengan-pasien-dalam-pengawasan-pdp",
        "type": "post",
        "title": {
          "rendered": "Apa Beda Orang Dalam Pemantauan (ODP) dengan Pasien Dalam Pengawasan (PDP)?"
        },
        "excerpt": {
          "rendered": "<p>Baca artikel ini supaya lebih tahu tentang Orang Dalam Pemantauan dan Pasien Dalam Pengawasan!</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 450,
        "date_gmt": "2020-03-16T00:27:00",
        "modified_gmt": "2021-03-02T04:50:13",
        "slug": "hampir-700-wni-hadiri-acara-di-pusat-penyebaran-covid-19-apa-respon-pemerintah",
        "type": "post",
        "title": {
          "rendered": "Hampir 700 WNI hadiri acara di pusat penyebaran COVID-19: Apa respon pemerintah?"
        },
        "excerpt": {
          "rendered": "<p>Dampak adanya acara besar terhadap penyebaran COVID-19 </p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 431,
        "date_gmt": "2020-03-14T12:11:00",
        "modified_gmt": "2021-03-02T04:50:22",
        "slug": "pencegahan-covid-19-di-lingkungan-institusi-pendidikan",
        "type": "post",
        "title": {
          "rendered": "Pencegahan COVID-19 di Lingkungan Institusi Pendidikan ﻿"
        },
        "excerpt": {
          "rendered": "<p>Beberapa hal yang dapat dilakukan untuk mencegah penularan COVID-19 di lingkungan pendidikan.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 416,
        "date_gmt": "2020-03-14T10:53:45",
        "modified_gmt": "2021-03-02T04:50:29",
        "slug": "do-dont-and-minimize-untuk-acara-besar",
        "type": "post",
        "title": {
          "rendered": "Do, Don&#8217;t and Minimize untuk Acara Besar"
        },
        "excerpt": {
          "rendered": "<p>Panduan untuk Mengurangi Risiko dalam Acara Besar</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 360,
        "date_gmt": "2020-03-13T03:52:52",
        "modified_gmt": "2021-03-02T04:50:36",
        "slug": "covid-19-mengapa-kita-harus-bertindak-sekarang",
        "type": "post",
        "title": {
          "rendered": "Covid-19: Mengapa Kita Harus Bertindak Sekarang!"
        },
        "excerpt": {
          "rendered": "<p>Dengan semua kejadian terkait Covid-19, saat ini, tentunya anda akan menghadapi kesulitan untuk mengambil keputusan hari ini. Apakah perlu menunggu informasi lebih lanjut atau melakukan sesuatu hari ini?</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 346,
        "date_gmt": "2020-03-12T15:33:23",
        "modified_gmt": "2021-03-02T04:50:43",
        "slug": "coronavirus-apa-yang-bisa-dilakukan-anak-anak",
        "type": "post",
        "title": {
          "rendered": "Coronavirus: Apa yang Bisa Dilakukan Anak-Anak?"
        },
        "excerpt": {
          "rendered": "<p>Yuk, simak hal apa saja yang dapat dilakukan anak-anak terkait coronavirus!</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 337,
        "date_gmt": "2020-03-12T09:37:29",
        "modified_gmt": "2021-03-02T04:50:51",
        "slug": "cara-gampang-membunuh-virus",
        "type": "post",
        "title": {
          "rendered": "Cara Gampang Membunuh Virus"
        },
        "excerpt": {
          "rendered": "<p>Alasan pentingnya rajin mencuci tangan dengan sabun.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 334,
        "date_gmt": "2020-03-12T03:46:37",
        "modified_gmt": "2021-03-02T04:50:58",
        "slug": "mengapa-kita-tidak-bisa-meremehkan-covid-19",
        "type": "post",
        "title": {
          "rendered": "Mengapa Kita Tidak Bisa Meremehkan COVID-19?"
        },
        "excerpt": {
          "rendered": "<p>Beberapa hal yang membuat kita harus penuh kewaspadaan dalam menghadapi COVID-19</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 282,
        "date_gmt": "2020-03-07T17:30:00",
        "modified_gmt": "2021-07-21T12:59:17",
        "slug": "kawalcovid19-id-untuk-mengurai-derasnya-informasi-seputar-covid-19",
        "type": "post",
        "title": {
          "rendered": "KawalCOVID19 untuk mengurai derasnya informasi seputar COVID-19"
        },
        "excerpt": {
          "rendered": "<p>Website kawalcovid19.id beserta akun-akun media sosialnya akan dimutakhirkan berkala dengan esensi Informasi, Verifikasi, dan Edukasi.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 2,
        "date_gmt": "2020-03-05T03:10:00",
        "modified_gmt": "2021-03-02T04:51:15",
        "slug": "faq",
        "type": "post",
        "title": {
          "rendered": "Tanya Jawab Seputar COVID-19"
        },
        "excerpt": {
          "rendered": "<p>Yang perlu Anda ketahui seputar COVID-19.</p>",
          "protected": false
        },
        "author": 2
      }
    ],
    "authors": {
      "2": {
        "id": 2,
        "name": "Kawal COVID-19",
        "description": "Tim administrator situs KawalCOVID19.id",
        "slug": "admin",
        "avatar_urls": {
          "24": "http://0.gravatar.com/avatar/662a7ed69e971f6fb58f2c0aff371674?s=24&d=retro&r=g",
          "48": "http://0.gravatar.com/avatar/662a7ed69e971f6fb58f2c0aff371674?s=48&d=retro&r=g",
          "96": "http://0.gravatar.com/avatar/662a7ed69e971f6fb58f2c0aff371674?s=96&d=retro&r=g"
        },
        "_links": {
          "self": [
            {
              "href": "http://18.141.204.243/index.php?rest_route=/wp/v2/users/2"
            }
          ],
          "collection": [
            {
              "href": "http://18.141.204.243/index.php?rest_route=/wp/v2/users"
            }
          ]
        }
      }
    }
  },
  "__N_SSG": true
}
