{
  "pageProps": {
    "posts": [
      {
        "id": 2148,
        "date_gmt": "2021-09-02T13:27:36",
        "modified_gmt": "2021-09-02T13:28:54",
        "slug": "pengalaman-karantina-mandiri-sepulang-dari-as",
        "type": "post",
        "title": {
          "rendered": "Pengalaman Karantina Mandiri Sepulang dari AS"
        },
        "excerpt": {
          "rendered": "<p>Akhir Juli lalu, saya dan suami berangkat ke AS setelah menunda beberapa bulan karena pandemi. Kami di sana selama dua minggu dan kembali ke Jakarta pada 5 Agustus 2021. Untuk itu, saya segera melengkapi diri dengan informasi mengenai tahapan yang harus dilewati saat pulang, termasuk keharusan menjalani karantina selama delapan hari.&nbsp; Begitu keluar dari pesawat, [&hellip;]</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1970,
        "date_gmt": "2021-06-30T01:56:00",
        "modified_gmt": "2021-06-30T01:56:15",
        "slug": "kalasuba-lockdown-vastraharan-drupadi",
        "type": "post",
        "title": {
          "rendered": "Kalasuba: Lockdown &#038; Vastraharan Drupadi"
        },
        "excerpt": {
          "rendered": "<p>oleh Sidrotun Naim28/6/2021 Vastraharan Punya suami lima Pandawa, harusnya hidup Drupadi serba enak. Lho, punya lima suami? Ya, begitulah versi asli Mahabharata, di versi wayang Jawa diperhalus bahwa dia hanya istri dari Yudistira, Pandawa tertua. Kurawa yang dipimpin Duryudana selalu ingin menjadi pewaris sah Hastinapura. Maka Hastinapura dibagi menjadi dua. Pandawa yang harus keluar, dengan [&hellip;]</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1925,
        "date_gmt": "2021-05-11T00:36:26",
        "modified_gmt": "2021-05-11T00:37:06",
        "slug": "ketika-harus-menyampaikan-kabar-buruk",
        "type": "post",
        "title": {
          "rendered": "Ketika Harus Menyampaikan Kabar Buruk"
        },
        "excerpt": {
          "rendered": "<p>Bagaimana mengkomunikasikan kabar buruk? Artikel ini membahas bagaimana berkomunikasi dengan empati terutama dalam konteks pandemi COVID-19.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1658,
        "date_gmt": "2021-02-27T02:25:18",
        "modified_gmt": "2021-03-01T02:11:05",
        "slug": "seri-penyintas-covid-19-klaster-keluarga-yang-masih-berjuang-untuk-pulih",
        "type": "post",
        "title": {
          "rendered": "Seri Penyintas COVID-19: Klaster Keluarga Yang Masih Berjuang Untuk Pulih"
        },
        "excerpt": {
          "rendered": "<p>Saya ingin membagi pengalaman kami sekeluarga untuk menjadi penyemangat teman-teman yang orang tua dan keluarganya terkena COVID-19. Kisah oleh @anindya_hafsa</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1638,
        "date_gmt": "2021-02-17T01:34:49",
        "modified_gmt": "2021-03-01T02:11:15",
        "slug": "kisahku-dan-covid-19",
        "type": "post",
        "title": {
          "rendered": "Seri Penyintas COVID-19: Kisahku dan COVID-19"
        },
        "excerpt": {
          "rendered": "<p>Penulis merasakan gejala yang mirip flu setelah pulang dari Bandung mengunjungi keluarganya. Ternyata penulis positif dan  hampir seluruh keluarganya terinfeksi COVID-19. Penulis menceritakan perjalanan perawatannya selama di RS.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1629,
        "date_gmt": "2021-02-03T08:03:57",
        "modified_gmt": "2021-03-01T02:11:24",
        "slug": "satu-tahun-evakuasi-dari-wuhan",
        "type": "post",
        "title": {
          "rendered": "Satu Tahun Evakuasi dari Wuhan"
        },
        "excerpt": {
          "rendered": "<p>Genap satu tahun penulis dipulangkan dari Wuhan bersama WNI lainnya. Penulis menceritakan kilas balik evakuasi mereka dari Wuhan, Tiongkok. Satu tahun berselang, Wuhan sebagai episentrum pertama COVID-19 telah kembali normal, sedangkan Indonesia masih berjuang untuk mengatasi pandemi ini. Ia mengajak Indonesia untuk menyontoh kegigihan Wuhan bersusah-susah dahulu agar kita bisa lebih cepat keluar dari pandemi.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1627,
        "date_gmt": "2021-02-03T08:02:45",
        "modified_gmt": "2021-03-01T02:11:34",
        "slug": "pengalaman-penderita-diabetes-terinfeksi-covid-19",
        "type": "post",
        "title": {
          "rendered": "Seri Penyintas COVID-19: Pengalaman Penderita Diabetes Terinfeksi COVID-19"
        },
        "excerpt": {
          "rendered": "<p>Setelah bertugas sebagai panitia KPPS (yang menyiapkan Pilkada di TPS masing-masing), penulis mengalami demam tinggi. Hasil rapid test non-reaktif, dan diagnosa pertama adalah typhus. Setelah beberapa hari di RS, kondisi tidak membaik dan hasil rontgen paru-paru menunjukkan infeksi COVID-19 yang divalidasi dengan hasil swab PCR positif.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1616,
        "date_gmt": "2021-01-24T04:07:21",
        "modified_gmt": "2021-03-01T02:11:42",
        "slug": "seri-penyintas-covid-19-saya-penyintas-dan-mengalami-long-covid",
        "type": "post",
        "title": {
          "rendered": "Seri Penyintas COVID-19: Saya Penyintas dan Mengalami Long COVID"
        },
        "excerpt": {
          "rendered": "<p>Cerita Juno, penyintas COVID-19 yang mengalami gejala Long COVID.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1606,
        "date_gmt": "2021-01-08T07:19:31",
        "modified_gmt": "2021-03-01T02:11:52",
        "slug": "catatan-penyintas-covid-19-bernatal-di-wisma-atlit",
        "type": "post",
        "title": {
          "rendered": "Catatan Penyintas COVID-19: Bernatal di Wisma Atlit"
        },
        "excerpt": {
          "rendered": "<p>Ketika berkumpul bersama keluarga, protokol kesehatan sering terlupakan dan menjadi celah penularan COVID-19. Penulis menceritakan kronologi terpaparnya dia dan keluarganya oleh COVID-19 sehingga merayakan Natal di Wisma Atlit</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1568,
        "date_gmt": "2020-12-31T03:24:38",
        "modified_gmt": "2021-03-01T02:12:01",
        "slug": "anak-kami-tertular-covid-19-di-sekolah-bagian-2",
        "type": "post",
        "title": {
          "rendered": "Seri Penyintas COVID-19: Anak Kami Tertular COVID-19 di Sekolah &#8211; bagian 2"
        },
        "excerpt": {
          "rendered": "<p>Kondisi COVID-19 di UK saat ini, walaupun lockdown diberlakukan namun sekolah  tetap dibuka. Dan tanpa kewajiban menggunakan masker bagi siswa dan guru</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1565,
        "date_gmt": "2020-12-31T03:21:59",
        "modified_gmt": "2021-03-01T02:12:12",
        "slug": "anak-kami-tertular-covid-19-di-sekolah-bagian-1",
        "type": "post",
        "title": {
          "rendered": "Seri Penyintas COVID-19: Anak Kami Tertular COVID-19 di Sekolah &#8211; bagian 1"
        },
        "excerpt": {
          "rendered": "<p>Benarkah sekolah sudah aman untuk dibuka? Bagaimana jika ada yang positif di sekolah?</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1536,
        "date_gmt": "2020-11-17T06:49:03",
        "modified_gmt": "2021-03-01T02:12:20",
        "slug": "seri-penyintas-covid-19-kami-positif-tapi-tidak-masuk-ke-data",
        "type": "post",
        "title": {
          "rendered": "Seri Penyintas COVID-19: Kami Positif Tapi Tidak Masuk ke Data"
        },
        "excerpt": {
          "rendered": "<p>Penulis dan keluarganya bergejala ringan tapi baru terbukti positif COVID-19 setelah swab kedua. Ia juga mempertanyakan kenapa data kasus positif tidak bertambah padahal keluarganya positif.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1498,
        "date_gmt": "2020-10-16T06:57:14",
        "modified_gmt": "2021-03-01T02:12:32",
        "slug": "seri-penyintas-covid-19-dua-kali-terpapar-dengan-gejala-yang-berbeda",
        "type": "post",
        "title": {
          "rendered": "Seri Penyintas COVID-19: Dua Kali Terpapar dengan Gejala yang Berbeda"
        },
        "excerpt": {
          "rendered": "<p>Bulan Maret 2020, penulis terpapar COVID-19 dengan gejala kritis. Di awal Juli 2020, penulis kembali terkena COVID-19 tapi tanpa gejala, beserta dengan istri dan anaknya. Cerita ini adalah contoh bagaimana seseorang bisa terkena COVID-19 lebih dari sekali (reinfeksi). </p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1487,
        "date_gmt": "2020-10-01T14:40:13",
        "modified_gmt": "2021-07-03T09:03:36",
        "slug": "kumpulan-cerita-pengawal-pagebluk",
        "type": "post",
        "title": {
          "rendered": "Kumpulan Cerita Pengawal Pagebluk"
        },
        "excerpt": {
          "rendered": "<p>Agar masyarakat umum lebih memahami kondisi pandemi di lapangan, kami mengumpulkan narasi orang pertama dari tenaga kesehatan, lab, dan pihak lain yang turut mengawal penanganan COVID-19 di berbagai daerah di Indonesia.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1483,
        "date_gmt": "2020-10-01T14:18:31",
        "modified_gmt": "2021-03-01T02:14:59",
        "slug": "seri-pengawal-pagebluk-pasien-mendadak-kritis-atau-meninggal-testing-lemah-dan-warga-abai",
        "type": "post",
        "title": {
          "rendered": "Seri Pengawal Pagebluk: Pasien Mendadak Kritis atau Meninggal, Testing Lemah, dan Warga Abai"
        },
        "excerpt": {
          "rendered": "<p>Jumlah kasus positif rendah bukan berarti sebuah daerah aman dari COVID-19. Seorang dokter di Provinsi Lampung melaporkan beberapa pasien yang ternyata positif COVID-19, mendadak kritis, atau bahkan meninggal dalam waktu singkat ketika sudah berada di RS rujukan COVID-19. Minimnya testing membuat warga tidak was-was dan akhirnya abai.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1477,
        "date_gmt": "2020-09-27T03:11:02",
        "modified_gmt": "2021-03-01T02:15:08",
        "slug": "cerita-covid-19-art-positif-meski-berkegiatan-di-sekitar-rumah-saja",
        "type": "post",
        "title": {
          "rendered": "Cerita COVID-19: ART Positif Meski Berkegiatan di Sekitar Rumah Saja"
        },
        "excerpt": {
          "rendered": "<p>Asisten rumah tangga (ART) penulis positif COVID-19 meski tinggal di rumah saja dan hanya keluar untuk keperluan tertentu. Sementara itu, ART lainnya meninggal dunia dua hari sejak gejala awal muncul. Pengalaman ini adalah contoh bagaimana rumah bisa menjadi klaster penyebaran.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1474,
        "date_gmt": "2020-09-24T06:25:43",
        "modified_gmt": "2021-03-01T02:15:18",
        "slug": "seri-penyintas-covid-19-perjuangan-mencari-rumah-sakit-dan-melewati-masa-kritis",
        "type": "post",
        "title": {
          "rendered": "Seri Penyintas COVID-19: Perjuangan Mencari Rumah Sakit dan Melewati Masa Kritis"
        },
        "excerpt": {
          "rendered": "<p>Seorang penyintas di Surabaya mengalami COVID-19 sekaligus demam berdarah. Selama berhari-hari, kondisinya kritis dan nyawanya bergantung pada ventilator. Ia selamat tapi kehilangan nenek tercinta akibat COVID-19.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1460,
        "date_gmt": "2020-08-31T15:25:25",
        "modified_gmt": "2021-03-01T02:15:27",
        "slug": "benarkah-virus-mutasi-d614g-10x-lebih-mudah-menginfeksi",
        "type": "post",
        "title": {
          "rendered": "Benarkah Virus Mutasi D614G 10x Lebih Mudah Menginfeksi?"
        },
        "excerpt": {
          "rendered": "<p>Menanggapi klaim Dr Noor Hisham bahwa virus mutasi D614G 10x lebih menular, KawalCOVID19.id memberikan analisis dan posisi kami terhadap isu ini.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1457,
        "date_gmt": "2020-08-28T14:00:57",
        "modified_gmt": "2021-03-01T02:15:37",
        "slug": "seri-garda-terdepan-suka-duka-merawat-pasien-covid-19-di-rs-suyoto",
        "type": "post",
        "title": {
          "rendered": "Seri Pengawal Pagebluk: Suka Duka Merawat Pasien COVID-19 di RS Suyoto"
        },
        "excerpt": {
          "rendered": "<p>Seorang dokter relawan di Jakarta menggambarkan situasi perawatan COVID-19 di lapangan, termasuk prosedur memakai APD setiap kali memeriksa pasien hingga kematian pasien akibat penyakit penyerta, bahkan pada pasien remaja.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1409,
        "date_gmt": "2020-08-24T08:24:50",
        "modified_gmt": "2021-03-01T02:15:45",
        "slug": "seri-garda-terdepan-kendala-lapangan-analis-medis-di-kalimantan-selatan",
        "type": "post",
        "title": {
          "rendered": "Seri Pengawal Pagebluk: Kendala Lapangan Analis Medis di Kalimantan Selatan"
        },
        "excerpt": {
          "rendered": "<p>Seorang analis kesehatan di Kalimantan Selatan memaparkan faktor-faktor yang menyebabkan hasil swab lama dipublikasikan, mulai dari kekurangan SDM, kecilnya kapasitas alat tes, hingga data yang tidak sinkron.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1399,
        "date_gmt": "2020-08-15T14:52:50",
        "modified_gmt": "2021-03-01T02:15:53",
        "slug": "seri-penyintas-covid-19-ibu-dan-anak-sesama-dokter-tertular-dan-sembuh-dari-covid-19",
        "type": "post",
        "title": {
          "rendered": "Seri Penyintas COVID-19: Ibu dan Anak Sesama Dokter Tertular dan Sembuh dari COVID-19"
        },
        "excerpt": {
          "rendered": "<p>Seorang dokter gigi (ibu) dan dokter umum (anak) terpapar COVID-19 di fasilitas kesehatan pada waktu yang berlainan. Meski gejalanya ringan, masa isolasi terasa berat. Belum lagi tuduhan yang diterima dari keluarga pasien bahwa COVID-19 ini konspirasi RS.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1390,
        "date_gmt": "2020-08-04T13:00:40",
        "modified_gmt": "2021-03-01T02:16:01",
        "slug": "seri-penyintas-covid-19-papaku-terus-sesak-napas-meski-sudah-dinyatakan-sembuh",
        "type": "post",
        "title": {
          "rendered": "Seri Penyintas COVID-19: Papaku Terus Sesak Napas Meski Sudah Dinyatakan Sembuh"
        },
        "excerpt": {
          "rendered": "<p>Lima minggu setelah dinyatakan sembuh dari COVID-19, ayah dari penulis masih terus sesak napas dan memakai masker oksigen di rumah.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1388,
        "date_gmt": "2020-08-04T12:58:01",
        "modified_gmt": "2021-03-01T02:16:08",
        "slug": "seri-penyintas-covid-19-dari-kehilangan-kerabat-hingga-donor-plasma-darah",
        "type": "post",
        "title": {
          "rendered": "Seri Penyintas COVID-19: Dari Kehilangan Kerabat Hingga Donor Plasma Darah"
        },
        "excerpt": {
          "rendered": "<p>Belum usai berduka setelah kerabatnya meninggal dunia akibat COVID-19, penulis terkonfirmasi positif. Dengan kesabaran selama perawatan di ruang isolasi RS, ia pun sembuh dan telah mendonorkan plasma darah untuk membantu pasien COVID-19 lain dengan kondisi kritis.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1383,
        "date_gmt": "2020-07-28T14:57:34",
        "modified_gmt": "2021-03-01T02:49:32",
        "slug": "seri-penyintas-covid-19-ketidakjujuran-pasien-icu-dan-keluarganya-membuat-saya-terpapar-dan-diintubasi",
        "type": "post",
        "title": {
          "rendered": "Seri Penyintas COVID-19: Ketidakjujuran Pasien ICU dan Keluarganya Membuat Saya Terpapar dan Diintubasi"
        },
        "excerpt": {
          "rendered": "<p>Akibat kebohongan satu orang, sedikitnya tujuh orang tim medis dan non-medis di RS rujukan COVID-19 di Yogyakarta terinfeksi. </p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1365,
        "date_gmt": "2020-07-23T04:53:42",
        "modified_gmt": "2021-03-01T08:12:34",
        "slug": "kumpulan-cerita-penyintas-covid-19",
        "type": "post",
        "title": {
          "rendered": "Kumpulan Cerita Penyintas COVID-19"
        },
        "excerpt": {
          "rendered": "<p>Berikut kisah penyintas COVID-19 yang berhasil kami kumpulkan selama ini.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1359,
        "date_gmt": "2020-07-22T14:49:14",
        "modified_gmt": "2021-03-01T02:49:48",
        "slug": "seri-penyintas-covid-19-tiga-bulan-bergumul-dengan-covid-19",
        "type": "post",
        "title": {
          "rendered": "Seri Penyintas COVID-19: Tiga Bulan Bergumul dengan COVID-19"
        },
        "excerpt": {
          "rendered": "<p>Sepulang dari Inggris, penulis mengalami anosmia (kehilangan indera penciuman). Selama tiga bulan lamanya, ia dinyatakan positif COVID-19 dengan total 10 tes swab.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1356,
        "date_gmt": "2020-07-22T14:23:01",
        "modified_gmt": "2021-03-01T02:49:55",
        "slug": "gambaran-penggunaan-ventilator-bagi-pasien-covid-19-kritis",
        "type": "post",
        "title": {
          "rendered": "Gambaran Penggunaan Ventilator bagi Pasien COVID-19 Kritis"
        },
        "excerpt": {
          "rendered": "<p>Inilah ilustrasi penggunaan ventilator bagi pasien COVID-19 dengan kondisi kritis.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1354,
        "date_gmt": "2020-07-22T14:19:11",
        "modified_gmt": "2021-03-01T02:50:03",
        "slug": "seri-penyintas-covid-19-virus-tidak-kenal-profesi-dan-usia",
        "type": "post",
        "title": {
          "rendered": "Seri Penyintas COVID-19: Virus Tidak Kenal Profesi dan Usia"
        },
        "excerpt": {
          "rendered": "<p>Seorang dokter di usia produktif di Bali terpapar COVID-19 dengan kondisi kritis. Kisahnya menyadarkan bahwa seseorang yang tahu betul soal dunia medis dan berusia relatif muda tidak serta merta kebal dari virus.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1349,
        "date_gmt": "2020-07-21T03:33:53",
        "modified_gmt": "2021-03-01T02:50:10",
        "slug": "kisah-dania-berjuang-sembuh-dengan-diagnosa-tidak-pasti",
        "type": "post",
        "title": {
          "rendered": "Kisah Dania: Berjuang Sembuh dengan Diagnosa Tidak Pasti"
        },
        "excerpt": {
          "rendered": "<p>Benarkah Rumah Sakit mempositifkan banyak orang demi mendapat insentif pemerintah?</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1347,
        "date_gmt": "2020-07-21T03:25:38",
        "modified_gmt": "2021-03-01T02:50:21",
        "slug": "kisah-mila-perjuangan-di-ruang-isolasi-dengan-status-pdp",
        "type": "post",
        "title": {
          "rendered": "Kisah Mila*: Perjuangan di Ruang Isolasi dengan Status PDP"
        },
        "excerpt": {
          "rendered": "<p>Benarkah Rumah Sakit mempositifkan banyak orang demi mendapat insentif pemerintah?</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1278,
        "date_gmt": "2020-06-12T12:47:02",
        "modified_gmt": "2021-03-01T02:50:31",
        "slug": "edukasi-benarkah-otg-tidak-menular",
        "type": "post",
        "title": {
          "rendered": "[EDUKASI] Benarkah OTG tidak menular?"
        },
        "excerpt": {
          "rendered": "<p>Meluruskan kesimpangsiuran tentang risiko OTG menularkan COVID-19</p>\n",
          "protected": false
        },
        "author": 62
      },
      {
        "id": 1225,
        "date_gmt": "2020-05-08T07:38:04",
        "modified_gmt": "2021-03-01T02:50:40",
        "slug": "seri-garda-terdepan-bertugas-sebagai-dokter-di-rs-darurat-covid-19",
        "type": "post",
        "title": {
          "rendered": "Seri Pengawal Pagebluk: Bertugas sebagai Dokter di RS Darurat COVID-19"
        },
        "excerpt": {
          "rendered": "<p>Seorang dokter yang ditugaskan di Rumah Sakit Darurat COVID-19 Wisma Atlet Kemayoran, Jakarta, menceritakan kesehariannya selama dua minggu pertama bertugas.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1192,
        "date_gmt": "2020-04-28T12:08:52",
        "modified_gmt": "2021-03-01T02:50:50",
        "slug": "seri-penyintas-covid-19-tidak-bergejala-serius-bukan-berarti-tidak-terinfeksi",
        "type": "post",
        "title": {
          "rendered": "Seri Penyintas COVID-19: Tidak Bergejala Serius Bukan Berarti Tidak Terinfeksi"
        },
        "excerpt": {
          "rendered": "<p>Seorang penyintas COVID-19 tanpa gejala serius menceritakan hari-hari selama isolasi dan pesan pentingnya bagi pasien lain.  </p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1190,
        "date_gmt": "2020-04-28T12:08:07",
        "modified_gmt": "2021-03-01T02:51:01",
        "slug": "bertahan-saja-sudah-cukup",
        "type": "post",
        "title": {
          "rendered": "Bertahan Saja Sudah Cukup"
        },
        "excerpt": {
          "rendered": "<p>Cerita dan saran bagaimana mengatasi rasa cemas karena bipolar disorder yang dapat diterapkan untuk mengatasi rasa cemas karena pandemi.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1181,
        "date_gmt": "2020-04-27T07:46:27",
        "modified_gmt": "2021-03-01T02:51:10",
        "slug": "tiga-hal-yang-penting-untuk-kebijakan-mitigasi-wabah-covid-19-berdasarkan-data-indonesia",
        "type": "post",
        "title": {
          "rendered": "Tiga Hal yang Penting untuk Kebijakan Mitigasi Wabah COVID-19 Berdasarkan Data Indonesia"
        },
        "excerpt": {
          "rendered": "<p>Mari simak pola-pola pasien COVID-19 di Indonesia berdasarkan data dan bagaimana kebijakan mitigasi yang tepat.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1179,
        "date_gmt": "2020-04-27T07:35:01",
        "modified_gmt": "2021-03-01T02:51:20",
        "slug": "belajar-dari-singapura-no-one-is-safe-until-everyone-is-safe",
        "type": "post",
        "title": {
          "rendered": "Belajar dari Singapura: No One is Safe Until Everyone is Safe"
        },
        "excerpt": {
          "rendered": "<p>Kasus baru COVID-19 di Singapura melonjak di bulan April 2020, jumlah penambahan kasus harian di Singapura. Apa yang terjadi di Singapura dalam beberapa minggu terakhir?</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1120,
        "date_gmt": "2020-04-20T03:20:49",
        "modified_gmt": "2021-03-01T02:51:37",
        "slug": "indonesia-mandiri-masker",
        "type": "post",
        "title": {
          "rendered": "Indonesia Mandiri Masker"
        },
        "excerpt": {
          "rendered": "<p>Yuk simak data penelitian faktual dan tervalidasi terkait manfaat masker kain buatan sendiri untuk mengurangi penularan virus penyebab COVID-19. </p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1094,
        "date_gmt": "2020-04-19T11:15:40",
        "modified_gmt": "2021-03-01T02:51:46",
        "slug": "saya-sudah-kehilangan-ayah-beberapa-anggota-keluarga-besar-dan-11-teman",
        "type": "post",
        "title": {
          "rendered": "Saya Sudah Kehilangan Ayah, Beberapa Anggota Keluarga Besar, dan 11 Teman"
        },
        "excerpt": {
          "rendered": "<p>Kisah nyata dari relawan KawalCovid19.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1006,
        "date_gmt": "2020-04-10T08:11:49",
        "modified_gmt": "2021-03-02T04:47:32",
        "slug": "cerita-dari-jerman-pengalaman-dua-wni-di-bochum-dan-frankfurt",
        "type": "post",
        "title": {
          "rendered": "Cerita dari Jerman: Pengalaman Dua WNI di Bochum dan Frankfurt"
        },
        "excerpt": {
          "rendered": "<p>Seorang pelajar dan seorang pekerja asal Indonesia yang tinggal di Jerman membagikan kisah penanganan pandemi oleh pemerintah Jerman</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 992,
        "date_gmt": "2020-04-10T08:07:59",
        "modified_gmt": "2021-03-02T04:47:39",
        "slug": "cerita-dari-taiwan-respons-tepat-taiwan-dalam-menghadapi-sars-cov-2-dan-covid-19",
        "type": "post",
        "title": {
          "rendered": "Cerita dari Taiwan: Respons Tepat Taiwan dalam Menghadapi SARS COV-2 dan COVID-19"
        },
        "excerpt": {
          "rendered": "<p>Taiwan disebut-sebut sebagai negara yang mengantisipasi penyebaran COVID-19 dengan baik sebelum wabah memburuk. Artikel ini merangkum hal-hal baik yang dilakukan oleh Taiwan dan yang dapat dijadikan contoh.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 966,
        "date_gmt": "2020-04-07T04:35:32",
        "modified_gmt": "2021-03-01T02:52:44",
        "slug": "tips-agar-tetap-positif-selama-bekerja-dari-rumah",
        "type": "post",
        "title": {
          "rendered": "Tips Agar Tetap Positif Selama Bekerja dari Rumah"
        },
        "excerpt": {
          "rendered": "<p>Jenuh bekerja dari rumah? Simak tips berikut bagi tenaga profesional dan pekerja lainnya agar pikiran tetap positif selama #DiRumahAja.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 964,
        "date_gmt": "2020-04-07T04:33:51",
        "modified_gmt": "2021-03-01T02:52:53",
        "slug": "bertahan-dari-hubungan-tidak-sehat-selama-pandemi-covid-19",
        "type": "post",
        "title": {
          "rendered": "Waspadai Kekerasan Dalam Rumah Tangga saat Isolasi Mandiri"
        },
        "excerpt": {
          "rendered": "<p>Himbauan #DiRumahAja membawa permasalahan tersendiri bagi mereka yang terlibat dalam hubungan tidak sehat (toxic relationship) di rumah. Jika Anda salah satunya, simak tips untuk mengendalikan situasi dan menyelamatkan diri.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 856,
        "date_gmt": "2020-04-01T05:16:29",
        "modified_gmt": "2021-03-01T02:53:01",
        "slug": "belajar-dari-vietnam",
        "type": "post",
        "title": {
          "rendered": "Belajar dari Vietnam"
        },
        "excerpt": {
          "rendered": "<p>Dengan sumber daya terbatas dibandingkan Korea Selatan, Vietnam mampu mengatasi pandemi dengan langkah-langkah cepat dan tepat.</p>\n",
          "protected": false
        },
        "author": 125
      },
      {
        "id": 839,
        "date_gmt": "2020-03-30T12:09:12",
        "modified_gmt": "2021-03-01T02:53:54",
        "slug": "polemik-penggunaan-vitamin-dan-suplemen-kesehatan",
        "type": "post",
        "title": {
          "rendered": "Polemik Penggunaan Vitamin dan Suplemen Kesehatan"
        },
        "excerpt": {
          "rendered": "<p> Di tengah-tengah wabah COVID-19, banyak informasi mengenai vitamin dan suplemen kesehatan yang kita terima baik dari pesan berantai WhatsApp, maupun nasihat dari teman-teman. Kami ingin Anda lebih mengerti tentang vitamin dan suplemen kesehatan, beserta penggunaannya bagi kesehatan Anda </p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 820,
        "date_gmt": "2020-03-29T04:45:03",
        "modified_gmt": "2021-03-01T02:53:45",
        "slug": "lockdown-bukan-berarti-ekonomi-berhenti-begini-caranya",
        "type": "post",
        "title": {
          "rendered": "Lockdown? Bukan berarti ekonomi berhenti. Begini caranya!"
        },
        "excerpt": {
          "rendered": "<p>New York sudah menerapkan lockdown. Kebijakan yang tadinya dikhawatirkan akan mengganggu stabilitas ekonomi ternyata hanya mengubah jenis mata pencaharian yang tersedia berkat dukungan dan intervensi pemerintah serta perusahaan-perusahaan besar.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 803,
        "date_gmt": "2020-03-28T15:33:36",
        "modified_gmt": "2021-03-01T02:54:02",
        "slug": "karantina-wilayah",
        "type": "post",
        "title": {
          "rendered": "Karantina Wilayah"
        },
        "excerpt": {
          "rendered": "<p>&#8230; a brief to the President</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 796,
        "date_gmt": "2020-03-28T02:21:59",
        "modified_gmt": "2021-03-01T02:54:11",
        "slug": "dari-de-epidemie-ke-wajah-abad-ini-menghadapi-wabah",
        "type": "post",
        "title": {
          "rendered": "Dari “De Epidemie” ke Wajah Abad ini Menghadapi Wabah"
        },
        "excerpt": {
          "rendered": "<p>COVID-19 bukan pandemi pertama yang Indonesia hadapi sepanjang sejarah. Seabad yang lalu, Hindia Belanda sebutan Indonesia di kala itu) mengalami Pandemi Flu. Artikel ini menelusuri fakta-fakta sejarah yang relevan dengan kondisi Indonesia di masa kini sebagai  pelajaran berharga.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 786,
        "date_gmt": "2020-03-27T09:08:54",
        "modified_gmt": "2021-03-01T02:54:22",
        "slug": "ingat-italia-4-minggu-di-depan-indonesia",
        "type": "post",
        "title": {
          "rendered": "Ingat Italia (4 Minggu di Depan Indonesia)"
        },
        "excerpt": {
          "rendered": "<p>Tulisan ini menjabarkan analisis tentang intervensi yang dilakukan pemerintah Italia untuk menekan laju jumlah kasus terkonfimasi positif COVID-19 di Italia dan dampaknya.</p>\n",
          "protected": false
        },
        "author": 125
      },
      {
        "id": 778,
        "date_gmt": "2020-03-26T12:32:20",
        "modified_gmt": "2021-03-01T02:59:39",
        "slug": "cerita-dari-filipina-menjalani-karantina-wilayah-lockdown-sebagai-diaspora",
        "type": "post",
        "title": {
          "rendered": "Cerita dari Filipina: Menjalani Karantina wilayah (lockdown) sebagai Diaspora"
        },
        "excerpt": {
          "rendered": "<p>Sepulang dari Indonesia dan tiba kembali di Filipina, koresponden kami disambut dengan karantina wilayah (lockdown) oleh pemerintah setempat. Simak pengalamannya menjalani hal tersebut sebagai diaspora Indonesia di Manila.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 764,
        "date_gmt": "2020-03-25T02:37:19",
        "modified_gmt": "2021-03-01T02:59:49",
        "slug": "catatan-dari-wuhan-part-1",
        "type": "post",
        "title": {
          "rendered": "Cerita dari Tiongkok: Catatan dari Wuhan"
        },
        "excerpt": {
          "rendered": "<p>Pengalaman tinggal di Wuhan, Cina di tengah pandemik COVID-19</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 745,
        "date_gmt": "2020-03-24T06:29:26",
        "modified_gmt": "2021-03-01T02:59:57",
        "slug": "cerita-dari-italia-a-cautionary-tale-peringatan-untuk-kita-semua",
        "type": "post",
        "title": {
          "rendered": "Cerita dari Italia: A Cautionary Tale (Peringatan untuk Kita Semua)"
        },
        "excerpt": {
          "rendered": "<p>Seorang mahasiswa S3 asal Indonesia membagikan pengalaman dan observasinya selama tinggal di Trieste, Italia di tengah pandemi COVID-19. Artikel ini penting untuk menjadi bahan renungan bagi masyarakat di Indonesia agar yang terjadi di Italia tidak perlu terulang di negara kita.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 698,
        "date_gmt": "2020-03-21T17:38:31",
        "modified_gmt": "2021-03-01T03:00:08",
        "slug": "mencegah-dan-menangani-stigma-sosial-seputar-covid-19",
        "type": "post",
        "title": {
          "rendered": "Mencegah dan Menangani Stigma Sosial Seputar COVID-19"
        },
        "excerpt": {
          "rendered": "<p>Pandemi COVID-19 muncul bersamaan dengan stigma sosial di tengah masyarakat. Namun, hal ini dapat dicegah dan ditangani bersama oleh individu maupun pihak-pihak terkait.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 679,
        "date_gmt": "2020-03-21T17:27:08",
        "modified_gmt": "2021-03-01T03:00:17",
        "slug": "cerita-dari-singapura-belajar-menjaga-diri-sendiri-dari-sekolah",
        "type": "post",
        "title": {
          "rendered": "Cerita dari Singapura: Belajar Menjaga Diri Sendiri dari Sekolah"
        },
        "excerpt": {
          "rendered": "<p>Dina Kharisma, seorang guru SD di Singapura, membagikan pengalamannya soal langkah-langkah yang dilakukan di sekolah ketika COVID-19 datang ke negeri singa tersebut.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 685,
        "date_gmt": "2020-03-21T17:25:08",
        "modified_gmt": "2021-03-01T03:00:25",
        "slug": "tips-menjaga-kesehatan-jiwa-saat-jaga-jarak",
        "type": "post",
        "title": {
          "rendered": "Tips Menjaga Kesehatan Jiwa Saat Jaga Jarak"
        },
        "excerpt": {
          "rendered": "<p>Tips untuk menjaga kesehatan jiwa pada saat masa karantina dan jaga jarak dulu.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 676,
        "date_gmt": "2020-03-21T17:22:38",
        "modified_gmt": "2021-03-01T03:00:36",
        "slug": "10-tips-meringankan-beban-tenaga-medis-di-lapangan",
        "type": "post",
        "title": {
          "rendered": "10 Tips Meringankan Beban Tenaga Medis di Lapangan"
        },
        "excerpt": {
          "rendered": "<p>Selain diam di rumah, bantu juga tenaga medis dengan melakukan hal-hal berikut ini.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 650,
        "date_gmt": "2020-03-21T04:51:36",
        "modified_gmt": "2021-03-01T03:00:45",
        "slug": "bagaimana-virus-corona-menyerang-tubuh",
        "type": "post",
        "title": {
          "rendered": "Bagaimana Virus Corona Menyerang Tubuh?"
        },
        "excerpt": {
          "rendered": "<p>Bagaimana SARS-CoV-2 menginfeksi dan menyerang tubuh, dan apa dampaknya pada tubuh selain paru-paru. </p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 628,
        "date_gmt": "2020-03-20T12:37:25",
        "modified_gmt": "2021-03-01T03:00:54",
        "slug": "vaksin-dan-covid-19",
        "type": "post",
        "title": {
          "rendered": "Vaksin dan COVID-19"
        },
        "excerpt": {
          "rendered": "<p>Vaksin COVID-19 sudah ada atau belum sih? Mari kita cari tahu kebenarannya.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 592,
        "date_gmt": "2020-03-18T12:43:56",
        "modified_gmt": "2021-03-01T03:01:10",
        "slug": "siapkan-anak-anda-menghadapi-wabah-koronavirus-covid-19",
        "type": "post",
        "title": {
          "rendered": "Siapkan Anak Anda Menghadapi Wabah Koronavirus (COVID-19)"
        },
        "excerpt": {
          "rendered": "<p>Checklist untuk orang tua agar dapat mempersiapkan anak menghadapi wabah COVID-19</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 594,
        "date_gmt": "2020-03-18T12:38:44",
        "modified_gmt": "2021-03-01T03:01:25",
        "slug": "wuhan-virus-korona-dan-kewaspadaan-kita",
        "type": "post",
        "title": {
          "rendered": "Wuhan, Virus Korona, dan Kewaspadaan Kita"
        },
        "excerpt": {
          "rendered": "<p>Bagaimana Cina meningkatkan kewaspadaan terhadap virus korona yang mewabah</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 548,
        "date_gmt": "2020-03-17T05:43:50",
        "modified_gmt": "2021-03-01T03:01:35",
        "slug": "dirjen-who-virus-covid-19-adalah-virus-yang-unik-dengan-karakteristik-yang-unik-pula",
        "type": "post",
        "title": {
          "rendered": "Dirjen WHO: ”Virus COVID-19 adalah Virus yang Unik dengan Karakteristik yang Unik Pula&#8221;"
        },
        "excerpt": {
          "rendered": "<p>Berikut penjelasan beberapa perbedaan karakteristik virus penyebab COVID-19 dan influenza.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 535,
        "date_gmt": "2020-03-16T13:32:39",
        "modified_gmt": "2021-03-01T03:01:45",
        "slug": "kegiatan-belajar-dan-bermain-di-rumah-untuk-anak-balita-dan-taman-kanak-kanak",
        "type": "post",
        "title": {
          "rendered": "Kegiatan Belajar dan Bermain di Rumah untuk Anak Balita dan Taman Kanak-Kanak"
        },
        "excerpt": {
          "rendered": "<p>Sekolah anak libur selama 2 minggu? Jangan panik! Kami sudah menyiapkan kegiatan yang dapat dilakukan bersama anak saat libur.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 524,
        "date_gmt": "2020-03-16T12:45:55",
        "modified_gmt": "2021-03-01T03:33:59",
        "slug": "siapkan-anak-anda-menghadapi-wabah-koronavirus",
        "type": "post",
        "title": {
          "rendered": "Siapkan Anak Anda Menghadapi Wabah Koronavirus"
        },
        "excerpt": {
          "rendered": "<p>Berbicara dengan Anak tentang COVID-19</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 349,
        "date_gmt": "2020-03-12T23:22:02",
        "modified_gmt": "2021-03-01T03:36:33",
        "slug": "cerita-dari-korea-selatan-pengalaman-ngantor-di-tengah-wabah",
        "type": "post",
        "title": {
          "rendered": "Cerita dari Korea Selatan: Pengalaman Ngantor di Tengah Wabah"
        },
        "excerpt": {
          "rendered": "<p>Pengaruh coronavirus terhadap kondisi kerja di Seoul, Korea.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 302,
        "date_gmt": "2020-03-10T04:27:20",
        "modified_gmt": "2021-03-01T03:36:42",
        "slug": "superspreader-setiap-orang-bisa-cegah-penularan-lebih-lanjut",
        "type": "post",
        "title": {
          "rendered": "Superspreader: Setiap Orang Bisa Cegah Penularan Lebih Lanjut"
        },
        "excerpt": {
          "rendered": "<p>Indonesia bisa belajar dari penyebaran luas COVID-19 di negara-negara lain yang bermula dari satu orang saja.</p>\n",
          "protected": false
        },
        "author": 82
      },
      {
        "id": 2192,
        "date_gmt": "2021-10-05T14:16:46",
        "modified_gmt": "2021-10-05T14:22:35",
        "slug": "pentingnya-vaksinasi-untuk-sekolah-tatap-muka",
        "type": "post",
        "title": {
          "rendered": "Pentingnya Vaksinasi Untuk Sekolah Tatap Muka"
        },
        "excerpt": {
          "rendered": "<p>Pembukaan sekolah sudah mulai berjalan. Namun pertanyaannya yang sama juga masih bergaung, “Amankah?”</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 2135,
        "date_gmt": "2021-07-24T03:35:57",
        "modified_gmt": "2021-07-24T03:36:26",
        "slug": "varian-delta-yang-perlu-anda-ketahui",
        "type": "post",
        "title": {
          "rendered": "Varian Delta: Yang Perlu Anda Ketahui"
        },
        "excerpt": {
          "rendered": "<p>Simak rangkuman tentang varian delta yang perlu anda ketahui</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 2066,
        "date_gmt": "2021-07-16T12:15:49",
        "modified_gmt": "2021-07-21T01:03:43",
        "slug": "panduan-menyambut-ibadah-idul-adha-2021",
        "type": "post",
        "title": {
          "rendered": "Panduan Menyambut Ibadah Idul Adha 2021 :"
        },
        "excerpt": {
          "rendered": "<p>Simak panduan berikut untuk menyambut ibadah Idul Adha 2021 yang dikeluarkan oleh Kementrian Agama</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1964,
        "date_gmt": "2021-06-19T07:04:06",
        "modified_gmt": "2021-06-19T07:49:26",
        "slug": "10-cara-indonesia-tangguh-melawan-covid-19",
        "type": "post",
        "title": {
          "rendered": "10 Cara Indonesia Tangguh Melawan COVID-19"
        },
        "excerpt": {
          "rendered": "<p>Wabah COVID19 kembali memuncak. Naiknya mobilitas masyarakat, mengendornya prosedur dan ketaatan akan protokol kesehatan di semua lini, dan masuknya varian Alfa dan Delta yang lebih cepat penyebarannya menyebabkan kasus positif harian terus meninggi. </p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1960,
        "date_gmt": "2021-06-18T14:14:17",
        "modified_gmt": "2021-06-18T14:15:06",
        "slug": "e-participation-against-covid-19-case-of-kawalcovid-19-id",
        "type": "post",
        "title": {
          "rendered": "E-Participation against COVID-19: Case of KawalCOVID-19.id"
        },
        "excerpt": {
          "rendered": "",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1956,
        "date_gmt": "2021-06-14T09:22:43",
        "modified_gmt": "2021-06-14T09:23:42",
        "slug": "vaksin-untuk-penyintas-covid-19-perlu-atau-tidak",
        "type": "post",
        "title": {
          "rendered": "Vaksin untuk Penyintas COVID-19, Perlu atau Tidak?"
        },
        "excerpt": {
          "rendered": "<p>Vaksinasi untuk penyintas COVID-19 apakah diperlukan? Simak bahasan berikut ini.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1931,
        "date_gmt": "2021-05-31T02:44:59",
        "modified_gmt": "2021-07-03T09:34:24",
        "slug": "cara-isolasi-mandiri-yang-benar",
        "type": "post",
        "title": {
          "rendered": "Cara Isolasi Mandiri yang Benar"
        },
        "excerpt": {
          "rendered": "<p>Tim KawalCOVID-19 berbincang dengan dr. Sayuri Suwandi, SpPD dan dr. Dirga Sakti Rambe, SpPD, untuk menjawab pertanyaan seputar cara isolasi mandiri (isoman) yang benar. Berikut ringkasan dari dua sesi Instagram Live yang bisa dilihat di sini.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1928,
        "date_gmt": "2021-05-12T15:22:00",
        "modified_gmt": "2021-05-12T15:24:39",
        "slug": "mengenal-dan-mencegah-varian-baru-virus-sars-cov-2",
        "type": "post",
        "title": {
          "rendered": "Mengenal dan Mencegah Varian Baru Virus SARS-CoV-2"
        },
        "excerpt": {
          "rendered": "<p>Virus SARS-CoV-2, yang menjadi penyebab munculnya COVID-19, telah bermutasi dan menyebar ke seluruh dunia. Mutasi menghasilkan beberapa varian baru, satu di antaranya ialah B.1.1.7 yang terdeteksi pertama kali di Inggris Raya pada September 2020.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1913,
        "date_gmt": "2021-04-29T01:25:03",
        "modified_gmt": "2021-04-29T01:25:42",
        "slug": "ramadhan-2021-tetap-waspada-menjaga-3m-dan-bersiap-untuk-tidak-mudik",
        "type": "post",
        "title": {
          "rendered": "Ramadhan 2021: Tetap Waspada, Menjaga 3M dan Bersiap untuk Tidak Mudik."
        },
        "excerpt": {
          "rendered": "<p>Refleksi di Ramadhan 2021 terkait kondisi pandemi COVID-19</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1906,
        "date_gmt": "2021-04-19T00:54:15",
        "modified_gmt": "2021-04-27T01:32:31",
        "slug": "gangguan-stres-pascatrauma-pada-penyintas-covid-19",
        "type": "post",
        "title": {
          "rendered": "Gangguan Stres Pascatrauma pada Penyintas COVID-19"
        },
        "excerpt": {
          "rendered": "<p>Dinyatakan sembuh dari COVID-19 tidak serta merta membuat para penyintas kembali hidup normal. Ada risiko penurunan kondisi fisik, dan juga kesehatan mental. Artikel ini membahas gangguan stres pasca trauma pada penyintas COVID-19</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1904,
        "date_gmt": "2021-04-19T00:53:13",
        "modified_gmt": "2021-04-19T01:01:02",
        "slug": "satu-tahun-covid-19-di-indonesia-waspadai-pandemic-fatigue",
        "type": "post",
        "title": {
          "rendered": "Satu Tahun COVID-19 di Indonesia, Waspadai Pandemic Fatigue!"
        },
        "excerpt": {
          "rendered": "<p>Berbagai adaptasi terhadap kebiasaan baru dan pembatasan yang terjadi selama satu tahun ini telah membuat sebagian besar dari kita merasa kelelahan. Artikel ini akan membahas tentang kelelahan pandemi (pandemic fatigue), dampak yang dapat ditimbulkan, dan kiat yang dapat kita lakukan bersama untuk mengatasinya.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1902,
        "date_gmt": "2021-04-19T00:51:49",
        "modified_gmt": "2021-04-19T01:00:29",
        "slug": "kipi-atau-reaksi-kecemasan-terkait-vaksinasi",
        "type": "post",
        "title": {
          "rendered": "KIPI atau Reaksi Kecemasan Terkait Vaksinasi?"
        },
        "excerpt": {
          "rendered": "<p>Hendaknya kita tidak perlu terlalu panik dan terburu-buru melabeli setiap keluhan yang muncul ketika atau setelah vaksinasi sebagai efek samping vaksin atau efek prosedur vaksinasi. Bisa jadi, itu adalah reaksi kecemasan terkait vaksinasi. Artikel ini menjelaskan berbagai jenis KIPI, cara membedakan, dan kiat mengatasinya baik dari sisi tenaga kesehatan yang bertugas maupun masyarakat penerima vaksin.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1879,
        "date_gmt": "2021-04-15T02:48:10",
        "modified_gmt": "2021-06-25T09:40:22",
        "slug": "satgas-kesehatan-di-sekolah-untuk-apa",
        "type": "post",
        "title": {
          "rendered": "Satgas Kesehatan di Sekolah, Untuk Apa?"
        },
        "excerpt": {
          "rendered": "<p>Per 24 Februari 2021, sudah ada wacana membuka kembali sekolah setelah vaksinasi guru rampung di bulan Juni. Di medio 2021, belum tentu pandemi sudah terkendali, apalagi berakhir. Karenanya, selain harus bersiap untuk mencegah penularan, sekolah juga harus bersiap diri untuk menangani adanya penularan di sekolah, sehingga tidak bingung dan menangani dengan serabutan ketika ada kasus. [&hellip;]</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1828,
        "date_gmt": "2021-04-05T14:44:30",
        "modified_gmt": "2021-04-05T14:44:51",
        "slug": "benarkah-covid-19-memicu-penyakit-ginjal",
        "type": "post",
        "title": {
          "rendered": "Benarkah COVID-19 Memicu Penyakit Ginjal?"
        },
        "excerpt": {
          "rendered": "<p>COVID-19 menimbulkan berbagai komplikasi penyakit, salah satunya penyakit ginjal. Beberapa penelitian menunjukkan bahwa pasien COVID-19 dapat menderita sakit ginjal, spesifiknya cedera ginjal akut. </p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1818,
        "date_gmt": "2021-04-01T03:05:23",
        "modified_gmt": "2021-07-03T09:03:10",
        "slug": "interaksi-guru-murid-yang-minim-risiko",
        "type": "post",
        "title": {
          "rendered": "Interaksi Guru-Murid Yang Minim Risiko"
        },
        "excerpt": {
          "rendered": "<p>Per 24 Februari 2021, sudah ada wacana bahwa pemerintah akan membuka sekolah setelah vaksinasi guru rampung di bulan Juni. Di medio 2021, belum tentu pandemi terkendali, apalagi berakhir. Karenanya, penting bagi pendidik untuk melengkapi diri dengan cara aman melakukan berinteraksi dengan anak didik.&nbsp; COVID-19 menyebar lewat droplet seperti air liur dan ingus, dua hal yang [&hellip;]</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1669,
        "date_gmt": "2021-03-18T14:44:28",
        "modified_gmt": "2021-04-05T14:51:50",
        "slug": "tb-dan-covid-19",
        "type": "post",
        "title": {
          "rendered": "TB dan COVID-19"
        },
        "excerpt": {
          "rendered": "<p>Penulis: Nabila Hana Wardhani Tuberkulosis dan COVID-19 memiliki gejala yang serupa tapi tak sama seperti adanya batuk, demam dan sesak napas. Penyebab dan pengobatan dari kedua penyakit tersebut juga berbeda. Apabila keduanya diidap oleh seseorang, perkiraan perjalanan COVID-19 seseorang akan menjadi lebih buruk. Selain itu pandemi membawa problematika baru bagi TB, seperti melonjaknya kasus resistensi [&hellip;]</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1660,
        "date_gmt": "2021-02-27T02:28:10",
        "modified_gmt": "2021-04-05T14:53:18",
        "slug": "vaksin-covid-19",
        "type": "post",
        "title": {
          "rendered": "Vaksin COVID-19"
        },
        "excerpt": {
          "rendered": "<p>Penjelasan singkat tentang vaksin COVID-19</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1645,
        "date_gmt": "2021-02-20T05:39:49",
        "modified_gmt": "2021-07-03T09:03:19",
        "slug": "seri-covid-19-artikel-2-sebelum-buka-sekolah-apa-yang-harus-dikomunikasikan",
        "type": "post",
        "title": {
          "rendered": "Sebelum Buka Sekolah, Apa yang Harus Dikomunikasikan?"
        },
        "excerpt": {
          "rendered": "<p>Kebanyakan sekolah mengerti bahwa ada protokol kesehatan yang harus disiapkan sebelum membuka sekolah di masa pandemi. Tapi dari berbagai input yang disampaikan oleh guru, murid, orang tua maupun administrator sekolah, tidak ada pedoman bagaimana mengkomunikasikan pembukaan sekolah dan perilaku seperti apa yang sekolah minta dari para guru, siswa dan orang tua murid.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1642,
        "date_gmt": "2021-02-20T05:35:56",
        "modified_gmt": "2021-04-07T10:24:29",
        "slug": "infrastruktur-apa-yang-perlu-disiapkan-sekolah-new-normal",
        "type": "post",
        "title": {
          "rendered": "Infrastruktur Apa yang Perlu Disiapkan Sekolah New Normal?"
        },
        "excerpt": {
          "rendered": "<p>Di satu titik, sekolah akan tatap muka lagi. Berapa lama lagi, kita belum tahu. Tapi sekolah perlu menyiapkan infrastruktur new normal mulai sekarang agar ketika nanti sekolah dibuka, semua pihak sudah mengerti apa yang harus dilakukan.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1612,
        "date_gmt": "2021-01-14T02:21:49",
        "modified_gmt": "2021-03-02T04:52:49",
        "slug": "pertimbangan-untuk-langkah-langkah-kesehatan-masyarakat-terkait-sekolah-dalam-konteks-covid-19",
        "type": "post",
        "title": {
          "rendered": "Pertimbangan untuk Langkah-Langkah Kesehatan Masyarakat Terkait Sekolah dalam Konteks COVID-19"
        },
        "excerpt": {
          "rendered": "",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 2206,
        "date_gmt": "2020-12-29T13:34:00",
        "modified_gmt": "2022-02-07T23:32:45",
        "slug": "laporan-donasi-dana-tahap-kedua-infrastruktur-kawalcovid19-id-di-azure-cloud",
        "type": "post",
        "title": {
          "rendered": "Laporan Donasi Dana Tahap Kedua Infrastruktur KawalCOVID19.id di Azure Cloud"
        },
        "excerpt": {
          "rendered": "<p>Tim KawalCOVID19.id menerima Donasi Tahap Kedua dari Program IEG Peduli COVID-19 Yayasan Indika. Donasi digunakan seutuhnya untuk pendanaan infrastruktur teknologi website KawalCOVID19.id yang menggunakan Azure Cloud. Rincian data donasi terlampir dan dapat diunduh (download) di sini.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1558,
        "date_gmt": "2020-12-22T15:15:43",
        "modified_gmt": "2021-03-02T04:36:10",
        "slug": "pandemic-fatigue",
        "type": "post",
        "title": {
          "rendered": "Pandemic Fatigue"
        },
        "excerpt": {
          "rendered": "<p>Bosan dengar masalah Covid? Inilah kenapa anda mengalami kelelahan pandemi atau pandemic fatigue</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1541,
        "date_gmt": "2020-11-26T02:03:54",
        "modified_gmt": "2021-03-02T04:36:21",
        "slug": "penapisan-covid-19-bagi-petugas-pilkada",
        "type": "post",
        "title": {
          "rendered": "Penapisan COVID-19 bagi Petugas Pilkada"
        },
        "excerpt": {
          "rendered": "<p>Tes antibodi saja tidak bisa diandalkan untuk mencegah penularan COVID-19 selama Pilkada. Artikel ini menjabarkan strategi pemilahan kelompok sesuai hasil tes PCR dan langkah selanjutnya yang perlu diambil.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 2201,
        "date_gmt": "2020-09-22T15:37:00",
        "modified_gmt": "2022-02-07T23:31:52",
        "slug": "laporan-donasi-dana-tahap-pertama-infrastruktur-kawalcovid19-id-di-azure-cloud",
        "type": "post",
        "title": {
          "rendered": "Laporan Donasi Dana Tahap Pertama Infrastruktur KawalCOVID19.id di Azure Cloud"
        },
        "excerpt": {
          "rendered": "<p>Tim KawalCOVID19.id menerima Donasi Tahap Pertama dari Program IEG Peduli COVID-19 Yayasan Indika. Donasi digunakan seutuhnya untuk pendanaan infrastruktur teknologi website KawalCOVID19.id yang menggunakan Azure Cloud. Rincian data donasi terlampir dan bisa diunduh (download) di sini.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1466,
        "date_gmt": "2020-09-17T05:27:39",
        "modified_gmt": "2021-03-02T04:36:37",
        "slug": "rendahnya-pelacakan-kontak-dan-tingginya-tingkat-positivitas-tes-latar-belakang-dan-prospek-psbb-ii-dki-jakarta",
        "type": "post",
        "title": {
          "rendered": "Rendahnya Pelacakan Kontak dan Tingginya Tingkat Positivitas Tes: Latar Belakang dan Prospek PSBB II DKI Jakarta"
        },
        "excerpt": {
          "rendered": "<p>Kami menjabarkan tiga tren penting yang menjelaskan lonjakan kasus di DKI Jakarta, yakni (1) memburuknya pelacakan, (2) meningkatnya tingkat positivitas tes meskipun jumlah tesnya bertambah dan (3) inefisiensi tes mandiri. Rekomendasi kami bagi Pemprov DKI agar PSBB kedua berhasil mengendalikan wabah: 1) tingkatkan rasio lacak isolasi hingga 30 kontak erat per 1 kasus baru, 2) pekerjakan tim pelacak kontak khusus, 3) lampaui protokol revisi ke-5 dan haruskan semua kontak erat dites usap meskipun tidak bergejala ketika dikarantina di fasilitas terpusat, 4) kurangi tes mandiri yang tidak perlu dan 5) pertimbangkan pendekatan “stick and carrot”.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1361,
        "date_gmt": "2020-07-22T14:56:02",
        "modified_gmt": "2021-03-02T04:36:44",
        "slug": "serial-data-virus-corona-4-cara-memperbaiki-indeks-kewaspadaan",
        "type": "post",
        "title": {
          "rendered": "Serial Data Virus Corona 4: Cara Memperbaiki Indeks Kewaspadaan"
        },
        "excerpt": {
          "rendered": "<p>Serial data virus corona ke-4 ini menjelaskan bagaimana cara memperbaiki indeks kewaspadaan</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1330,
        "date_gmt": "2020-07-09T12:09:26",
        "modified_gmt": "2021-03-02T04:36:53",
        "slug": "serial-data-virus-korona-3-indeks-kewaspadaan",
        "type": "post",
        "title": {
          "rendered": "Serial Data Virus Korona 3: Indeks Kewaspadaan"
        },
        "excerpt": {
          "rendered": "<p>Serial data virus korona 3 ini merupakan kelanjutan dari serial data 1 dan 2 yang menggambarkan indeks kewaspadaan daerah pengidap positif COVID-19. Dengan adanya indeks kewaspadaan ini, dapat dapat terlihat gambaran relatif berapa besar kemungkinan terjadi transmisi COVID-19 di daerah domisili Anda.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1294,
        "date_gmt": "2020-07-04T14:02:35",
        "modified_gmt": "2021-03-02T04:37:01",
        "slug": "serial-data-virus-korona-2-rasio-lacak-isolasi-rli-dan-korelasinya-dengan-kematian-kumulatif",
        "type": "post",
        "title": {
          "rendered": "Serial Data Virus Korona 2: Rasio Lacak &#8211; Isolasi (RLI) dan Korelasinya dengan Kematian Kumulatif"
        },
        "excerpt": {
          "rendered": "<p>Serial data kedua ini merupakan kelanjutan dari serial data pertama yang memmbahas tentang rasio lacak-isolasi dan hubungannya dengan jumlah kematian</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1288,
        "date_gmt": "2020-07-04T14:00:18",
        "modified_gmt": "2021-03-02T04:37:11",
        "slug": "serial-data-virus-korona-1-3t-trace-test-treat",
        "type": "post",
        "title": {
          "rendered": "Serial Data Virus Korona 1: 3T (Trace, Test, Treat)"
        },
        "excerpt": {
          "rendered": "<p>Serial data pertama ini menyajikan bagaimana menurunkan penyebaran COVID-19  melalui 3T (Trace, Test, Treat)</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1284,
        "date_gmt": "2020-06-15T15:45:44",
        "modified_gmt": "2021-03-02T04:53:16",
        "slug": "mpasi-anak-dalam-kondisi-darurat",
        "type": "post",
        "title": {
          "rendered": "MPASI Anak dalam Kondisi Darurat"
        },
        "excerpt": {
          "rendered": "<p>Menjaga asupan gizi anak penting di tengah pandemi dan mudah dilakukan dengan memberikan Menu 4 Bintang.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1268,
        "date_gmt": "2020-05-15T10:11:37",
        "modified_gmt": "2021-03-16T17:30:39",
        "slug": "ramadan-psbb-dan-mcdonalds",
        "type": "post",
        "title": {
          "rendered": "Ramadan, PSBB dan McDonalds"
        },
        "excerpt": {
          "rendered": "<p>Berikut beberapa contoh kejadian yang dapat meningkatkan penyebaran virus korona.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1215,
        "date_gmt": "2020-05-03T12:38:09",
        "modified_gmt": "2021-03-02T04:45:12",
        "slug": "tips-pengolahan-makanan-dengan-penanak-nasi",
        "type": "post",
        "title": {
          "rendered": "Tips Pengolahan Makanan dengan Penanak Nasi"
        },
        "excerpt": {
          "rendered": "<p>Tips ini berisi cara memasak setiap kelompok bahan makanan menggunakan penanak nasi (rice cooker) untuk mempertahankan nilai gizi dengan menghindari pemanasan terlalu tinggi dalam waktu lama.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1183,
        "date_gmt": "2020-04-27T07:52:40",
        "modified_gmt": "2021-03-02T04:45:22",
        "slug": "rapid-test-atau-swab-test-apa-bedanya-mana-yang-lebih-baik",
        "type": "post",
        "title": {
          "rendered": "Rapid test atau swab test: Apa bedanya? Mana yang lebih baik?"
        },
        "excerpt": {
          "rendered": "<p>Perbandingan metode rapid test dan swab test dalam pendeteksian COVID-19.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1177,
        "date_gmt": "2020-04-27T07:33:49",
        "modified_gmt": "2021-03-02T04:53:36",
        "slug": "imunisasi-rutin-selama-pandemi-covid-19",
        "type": "post",
        "title": {
          "rendered": "Imunisasi Rutin Selama Pandemi COVID-19"
        },
        "excerpt": {
          "rendered": "<p>Panduan untuk imunisasi rutin selama adanya pandemi COVID-19.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1090,
        "date_gmt": "2020-04-17T12:36:06",
        "modified_gmt": "2021-03-02T04:46:02",
        "slug": "hoax-dan-misinformasi-seputar-covid-19-apa-yang-bisa-kita-lakukan",
        "type": "post",
        "title": {
          "rendered": "Hoax dan Misinformasi Seputar COVID-19; Apa yang Bisa Kita Lakukan?"
        },
        "excerpt": {
          "rendered": "<p>Perkembangan Terkini Seputar Hoax COVID-19</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1079,
        "date_gmt": "2020-04-15T05:47:19",
        "modified_gmt": "2021-03-02T04:53:52",
        "slug": "himbauan-untuk-vaksinasi",
        "type": "post",
        "title": {
          "rendered": "Himbauan untuk Vaksinasi"
        },
        "excerpt": {
          "rendered": "<p>Dokumen terkait himbauan untuk pelaksanaan vaksinasi anak selama pandemi COVID-19.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1021,
        "date_gmt": "2020-04-11T12:43:35",
        "modified_gmt": "2021-03-02T04:47:08",
        "slug": "apakah-penyemprotan-disinfektan-efektif-membunuh-sars-cov-2",
        "type": "post",
        "title": {
          "rendered": "Apakah Penyemprotan Disinfektan Efektif Membunuh SARS-COV-2?"
        },
        "excerpt": {
          "rendered": "<p>Beberapa hal yang harus diketahui tentang penyemprotan cairan disinfektan.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1006,
        "date_gmt": "2020-04-10T08:11:49",
        "modified_gmt": "2021-03-02T04:47:32",
        "slug": "cerita-dari-jerman-pengalaman-dua-wni-di-bochum-dan-frankfurt",
        "type": "post",
        "title": {
          "rendered": "Cerita dari Jerman: Pengalaman Dua WNI di Bochum dan Frankfurt"
        },
        "excerpt": {
          "rendered": "<p>Seorang pelajar dan seorang pekerja asal Indonesia yang tinggal di Jerman membagikan kisah penanganan pandemi oleh pemerintah Jerman</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 992,
        "date_gmt": "2020-04-10T08:07:59",
        "modified_gmt": "2021-03-02T04:47:39",
        "slug": "cerita-dari-taiwan-respons-tepat-taiwan-dalam-menghadapi-sars-cov-2-dan-covid-19",
        "type": "post",
        "title": {
          "rendered": "Cerita dari Taiwan: Respons Tepat Taiwan dalam Menghadapi SARS COV-2 dan COVID-19"
        },
        "excerpt": {
          "rendered": "<p>Taiwan disebut-sebut sebagai negara yang mengantisipasi penyebaran COVID-19 dengan baik sebelum wabah memburuk. Artikel ini merangkum hal-hal baik yang dilakukan oleh Taiwan dan yang dapat dijadikan contoh.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 972,
        "date_gmt": "2020-04-07T11:23:11",
        "modified_gmt": "2021-03-02T04:47:48",
        "slug": "cerita-dari-swedia-dont-try-this-at-home-jangan-terapkan-di-indonesia",
        "type": "post",
        "title": {
          "rendered": "Cerita dari Swedia: Don’t try this at home! (Jangan Terapkan di Indonesia!)"
        },
        "excerpt": {
          "rendered": "<p>Swedia dan Indonesia memiliki kondisi sosial dan budaya yang berbeda. Maka, kebijakan pemerintah Swedia tidak semerta-merta dapat diterapkan di Indonesia karena perbedaan konteks memerlukan penanganan yang berbeda pula.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 950,
        "date_gmt": "2020-04-05T07:22:31",
        "modified_gmt": "2021-03-02T04:47:59",
        "slug": "edukasi-saya-pengidap-asma-apa-yang-harus-saya-persiapkan",
        "type": "post",
        "title": {
          "rendered": "[EDUKASI] Saya pengidap asma. Apa yang harus saya persiapkan?"
        },
        "excerpt": {
          "rendered": "<p>Yuk simak beberapa saran yang harus Anda lakukan jika mengidap kondisi medis kronis.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 880,
        "date_gmt": "2020-04-03T05:37:16",
        "modified_gmt": "2021-03-02T04:48:08",
        "slug": "komunikasi-risiko-covid-19-untuk-fasilitas-pelayanan-kesehatan",
        "type": "post",
        "title": {
          "rendered": "Komunikasi Risiko COVID-19 untuk Fasilitas Pelayanan Kesehatan"
        },
        "excerpt": {
          "rendered": "<p>Materi ini memberikan gambaran langkah-langkah utama menjaga keselamatan dan kesehatan di tempat kerja untuk manajemen fasilitas pelayanan kesehatan dan tenaga kesehatan.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 843,
        "date_gmt": "2020-03-31T04:43:23",
        "modified_gmt": "2021-03-02T04:48:26",
        "slug": "dunia-dalam-berita-covid-19",
        "type": "post",
        "title": {
          "rendered": "Dunia dalam Berita COVID-19"
        },
        "excerpt": {
          "rendered": "<p>Rangkuman berita terkini seputar Covid-19, termasuk Fatwa MUI terkait pengurusan jenazah.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 800,
        "date_gmt": "2020-03-28T05:43:05",
        "modified_gmt": "2021-03-02T04:48:44",
        "slug": "informasi-terkini-28-maret-2020",
        "type": "post",
        "title": {
          "rendered": "Informasi terkini &#8211; 28 Maret 2020"
        },
        "excerpt": {
          "rendered": "<p>Monitor pemberitaan media per-harinya terkait covid-19</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 724,
        "date_gmt": "2020-03-21T18:24:53",
        "modified_gmt": "2021-03-02T04:48:54",
        "slug": "tips-hidup-sehat-agar-tubuh-lebih-tahan-terhadap-penyakit",
        "type": "post",
        "title": {
          "rendered": "Tips Hidup Sehat Agar Tubuh Lebih Tahan terhadap Penyakit"
        },
        "excerpt": {
          "rendered": "<p>Kekebalan tubuh memegang peran penting dalam menjaga tubuh dari penyakit.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 639,
        "date_gmt": "2020-03-20T13:18:03",
        "modified_gmt": "2021-03-02T04:49:15",
        "slug": "polemik-penggunaan-ibuprofen",
        "type": "post",
        "title": {
          "rendered": "Polemik penggunaan Ibuprofen"
        },
        "excerpt": {
          "rendered": "<p>Apakah ibuprofen memperburuk infeksi COVID-19? &#8211; Bagaimana dengan obat penawar rasa sakit yang lainnya?</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 721,
        "date_gmt": "2020-03-18T18:15:00",
        "modified_gmt": "2021-03-02T04:49:24",
        "slug": "penanganan-jenazah-yang-terduga-odp-kemungkinan-pdp-dan-terkonfirmasi-positif-terinfeksi-covid-19",
        "type": "post",
        "title": {
          "rendered": "Penanganan Jenazah yang Terduga (ODP) / Kemungkinan (PDP) dan Terkonfirmasi (Positif) Terinfeksi COVID-19"
        },
        "excerpt": {
          "rendered": "<p>Meskipun belum ada korban COVID-19, pemerintah Singapura sudah mempersiapkan panduan penanganan jenazah untuk pemakaman dan kremasi</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 546,
        "date_gmt": "2020-03-17T05:37:28",
        "modified_gmt": "2021-03-02T04:49:32",
        "slug": "coronavirus-covid-19-bagaimana-mempersiapkan-keluarga-anda",
        "type": "post",
        "title": {
          "rendered": "Coronavirus (COVID-19): Bagaimana Mempersiapkan Keluarga Anda?"
        },
        "excerpt": {
          "rendered": "<p>Beberapa hal yang dapat dipersiapkan untuk keluarga Anda dalam menghadapi COVID-19.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 510,
        "date_gmt": "2020-03-16T10:01:50",
        "modified_gmt": "2021-03-02T04:49:40",
        "slug": "dos-and-donts-dalam-social-distancing",
        "type": "post",
        "title": {
          "rendered": "Do&#8217;s and Don&#8217;ts dalam Social Distancing"
        },
        "excerpt": {
          "rendered": "<p>Kuncinya hanya satu: Apakah kondisi tubuh kita sehat dan apakah kita punya gejala sakit? </p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 463,
        "date_gmt": "2020-03-16T07:17:28",
        "modified_gmt": "2021-03-02T04:49:50",
        "slug": "protokol-isolasi-mandiri-home-quarantine",
        "type": "post",
        "title": {
          "rendered": "Protokol Isolasi Mandiri (Home Quarantine)"
        },
        "excerpt": {
          "rendered": "<p>Langkah-langkah yang dapat dilakukan ketika isolasi mandiri untuk mencegah penularan</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 466,
        "date_gmt": "2020-03-16T06:55:29",
        "modified_gmt": "2021-03-02T04:49:58",
        "slug": "tips-menghadapi-karantina-di-rumah",
        "type": "post",
        "title": {
          "rendered": "Tips Menghadapi Karantina di Rumah"
        },
        "excerpt": {
          "rendered": "<p>Karantina membantu memperlambat penyebaran infeksi. Siapkan sebuah Perencanaan Karantina Rumah</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 464,
        "date_gmt": "2020-03-16T06:48:58",
        "modified_gmt": "2021-03-02T04:50:06",
        "slug": "apa-beda-orang-dalam-pemantauan-odp-dengan-pasien-dalam-pengawasan-pdp",
        "type": "post",
        "title": {
          "rendered": "Apa Beda Orang Dalam Pemantauan (ODP) dengan Pasien Dalam Pengawasan (PDP)?"
        },
        "excerpt": {
          "rendered": "<p>Baca artikel ini supaya lebih tahu tentang Orang Dalam Pemantauan dan Pasien Dalam Pengawasan!</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 450,
        "date_gmt": "2020-03-16T00:27:00",
        "modified_gmt": "2021-03-02T04:50:13",
        "slug": "hampir-700-wni-hadiri-acara-di-pusat-penyebaran-covid-19-apa-respon-pemerintah",
        "type": "post",
        "title": {
          "rendered": "Hampir 700 WNI hadiri acara di pusat penyebaran COVID-19: Apa respon pemerintah?"
        },
        "excerpt": {
          "rendered": "<p>Dampak adanya acara besar terhadap penyebaran COVID-19 </p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 431,
        "date_gmt": "2020-03-14T12:11:00",
        "modified_gmt": "2021-03-02T04:50:22",
        "slug": "pencegahan-covid-19-di-lingkungan-institusi-pendidikan",
        "type": "post",
        "title": {
          "rendered": "Pencegahan COVID-19 di Lingkungan Institusi Pendidikan ﻿"
        },
        "excerpt": {
          "rendered": "<p>Beberapa hal yang dapat dilakukan untuk mencegah penularan COVID-19 di lingkungan pendidikan.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 416,
        "date_gmt": "2020-03-14T10:53:45",
        "modified_gmt": "2021-03-02T04:50:29",
        "slug": "do-dont-and-minimize-untuk-acara-besar",
        "type": "post",
        "title": {
          "rendered": "Do, Don&#8217;t and Minimize untuk Acara Besar"
        },
        "excerpt": {
          "rendered": "<p>Panduan untuk Mengurangi Risiko dalam Acara Besar</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 360,
        "date_gmt": "2020-03-13T03:52:52",
        "modified_gmt": "2021-03-02T04:50:36",
        "slug": "covid-19-mengapa-kita-harus-bertindak-sekarang",
        "type": "post",
        "title": {
          "rendered": "Covid-19: Mengapa Kita Harus Bertindak Sekarang!"
        },
        "excerpt": {
          "rendered": "<p>Dengan semua kejadian terkait Covid-19, saat ini, tentunya anda akan menghadapi kesulitan untuk mengambil keputusan hari ini. Apakah perlu menunggu informasi lebih lanjut atau melakukan sesuatu hari ini?</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 346,
        "date_gmt": "2020-03-12T15:33:23",
        "modified_gmt": "2021-03-02T04:50:43",
        "slug": "coronavirus-apa-yang-bisa-dilakukan-anak-anak",
        "type": "post",
        "title": {
          "rendered": "Coronavirus: Apa yang Bisa Dilakukan Anak-Anak?"
        },
        "excerpt": {
          "rendered": "<p>Yuk, simak hal apa saja yang dapat dilakukan anak-anak terkait coronavirus!</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 337,
        "date_gmt": "2020-03-12T09:37:29",
        "modified_gmt": "2021-03-02T04:50:51",
        "slug": "cara-gampang-membunuh-virus",
        "type": "post",
        "title": {
          "rendered": "Cara Gampang Membunuh Virus"
        },
        "excerpt": {
          "rendered": "<p>Alasan pentingnya rajin mencuci tangan dengan sabun.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 334,
        "date_gmt": "2020-03-12T03:46:37",
        "modified_gmt": "2021-03-02T04:50:58",
        "slug": "mengapa-kita-tidak-bisa-meremehkan-covid-19",
        "type": "post",
        "title": {
          "rendered": "Mengapa Kita Tidak Bisa Meremehkan COVID-19?"
        },
        "excerpt": {
          "rendered": "<p>Beberapa hal yang membuat kita harus penuh kewaspadaan dalam menghadapi COVID-19</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 282,
        "date_gmt": "2020-03-07T17:30:00",
        "modified_gmt": "2021-07-21T12:59:17",
        "slug": "kawalcovid19-id-untuk-mengurai-derasnya-informasi-seputar-covid-19",
        "type": "post",
        "title": {
          "rendered": "KawalCOVID19 untuk mengurai derasnya informasi seputar COVID-19"
        },
        "excerpt": {
          "rendered": "<p>Website kawalcovid19.id beserta akun-akun media sosialnya akan dimutakhirkan berkala dengan esensi Informasi, Verifikasi, dan Edukasi.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 2,
        "date_gmt": "2020-03-05T03:10:00",
        "modified_gmt": "2021-03-02T04:51:15",
        "slug": "faq",
        "type": "post",
        "title": {
          "rendered": "Tanya Jawab Seputar COVID-19"
        },
        "excerpt": {
          "rendered": "<p>Yang perlu Anda ketahui seputar COVID-19.</p>",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1485,
        "date_gmt": "2020-10-01T14:19:43",
        "modified_gmt": "2020-10-01T14:20:17",
        "slug": "bahan-bahan-memperkenalkan-covid-19-ke-anak-usia-dini",
        "type": "post",
        "title": {
          "rendered": "Bahan-bahan Memperkenalkan Covid-19 ke Anak Usia Dini"
        },
        "excerpt": {
          "rendered": "<p>Memperkenalkan COVID-19 ke anak-anak usia dini (3-6 tahun) sangat penting. Di bawah ini adalah beberapa resources berupa link, video, infografika, printables yang bisa diprint untuk semua. 3M&nbsp; Apa itu Covid :&nbsp; eBook :&nbsp; Cerita si Korona karangan Watiek Ideo https://online.fliphtml5.com/nwyyb/grdh/#p=1&nbsp; Gara-gara si Korona karangan Watiek Ideo https://online.fliphtml5.com/nwyyb/csin/#p=1&nbsp; Perjalanan si Korona karangan Watiek Ideo https://online.fliphtml5.com/nwyyb/lnjo/#p=10&nbsp; Video [&hellip;]</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 817,
        "date_gmt": "2020-03-29T03:25:00",
        "modified_gmt": "2021-07-03T09:03:48",
        "slug": "daftar-layanan-konseling-online-se-indonesia",
        "type": "post",
        "title": {
          "rendered": "Daftar Layanan Konseling Online Se-Indonesia"
        },
        "excerpt": {
          "rendered": "<p>Kami mengumpulkan berbagai poster informasi tentang layanan konseling online yang tersebar di berbagai wilayah.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 644,
        "date_gmt": "2020-03-21T03:51:30",
        "modified_gmt": "2020-04-06T10:04:12",
        "slug": "daftar-gereja-dengan-ibadah-online",
        "type": "post",
        "title": {
          "rendered": "Daftar Gereja dengan Ibadah Online"
        },
        "excerpt": {
          "rendered": "<p>Kami mengajak semua umat Nasrani ikut berpartisipasi dalam mencegah penyebaran COVID-19 dengan melakukan #JagaJarakDulu dan #DiamDiRumah.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 492,
        "date_gmt": "2020-03-18T13:25:00",
        "modified_gmt": "2020-04-03T14:05:04",
        "slug": "jadwal-harian-covid-19-usia-sd",
        "type": "post",
        "title": {
          "rendered": "Jadwal Harian COVID-19 Usia SD"
        },
        "excerpt": {
          "rendered": "<p>Kegiatan harian yang dapat dilakukan anak untuk usia SD.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 2137,
        "date_gmt": "2021-07-24T03:51:55",
        "modified_gmt": "2021-07-24T03:59:47",
        "slug": "revisi-panduan-tata-laksana-covid-19-di-indonesia",
        "type": "post",
        "title": {
          "rendered": "Revisi Panduan Tata Laksana COVID-19 di Indonesia"
        },
        "excerpt": {
          "rendered": "<p>Pada tanggal 14 Juli 2021, organisasi profesi dokter di Indonesia, yaitu Perhimpunan Dokter Paru Indonesia (PDPI), Perhimpunan Dokter Spesialis Kardiovaskular Indonesia (PERKI), Perhimpunan Dokter Spesialis Penyakit Dalam Indonesia (PAPDI), Perhimpunan Dokter Spesialis Anestesiologi dan Terapi Intensif (PERDATIN), dan Ikatan Dokter Anak Indonesia (IDAI) merevisi rekomendasi panduan tata laksana COVID-19 di Indonesia. Revisi ini ditujukan untuk [&hellip;]</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1931,
        "date_gmt": "2021-05-31T02:44:59",
        "modified_gmt": "2021-07-03T09:34:24",
        "slug": "cara-isolasi-mandiri-yang-benar",
        "type": "post",
        "title": {
          "rendered": "Cara Isolasi Mandiri yang Benar"
        },
        "excerpt": {
          "rendered": "<p>Tim KawalCOVID-19 berbincang dengan dr. Sayuri Suwandi, SpPD dan dr. Dirga Sakti Rambe, SpPD, untuk menjawab pertanyaan seputar cara isolasi mandiri (isoman) yang benar. Berikut ringkasan dari dua sesi Instagram Live yang bisa dilihat di sini.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1879,
        "date_gmt": "2021-04-15T02:48:10",
        "modified_gmt": "2021-06-25T09:40:22",
        "slug": "satgas-kesehatan-di-sekolah-untuk-apa",
        "type": "post",
        "title": {
          "rendered": "Satgas Kesehatan di Sekolah, Untuk Apa?"
        },
        "excerpt": {
          "rendered": "<p>Per 24 Februari 2021, sudah ada wacana membuka kembali sekolah setelah vaksinasi guru rampung di bulan Juni. Di medio 2021, belum tentu pandemi sudah terkendali, apalagi berakhir. Karenanya, selain harus bersiap untuk mencegah penularan, sekolah juga harus bersiap diri untuk menangani adanya penularan di sekolah, sehingga tidak bingung dan menangani dengan serabutan ketika ada kasus. [&hellip;]</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1818,
        "date_gmt": "2021-04-01T03:05:23",
        "modified_gmt": "2021-07-03T09:03:10",
        "slug": "interaksi-guru-murid-yang-minim-risiko",
        "type": "post",
        "title": {
          "rendered": "Interaksi Guru-Murid Yang Minim Risiko"
        },
        "excerpt": {
          "rendered": "<p>Per 24 Februari 2021, sudah ada wacana bahwa pemerintah akan membuka sekolah setelah vaksinasi guru rampung di bulan Juni. Di medio 2021, belum tentu pandemi terkendali, apalagi berakhir. Karenanya, penting bagi pendidik untuk melengkapi diri dengan cara aman melakukan berinteraksi dengan anak didik.&nbsp; COVID-19 menyebar lewat droplet seperti air liur dan ingus, dua hal yang [&hellip;]</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1645,
        "date_gmt": "2021-02-20T05:39:49",
        "modified_gmt": "2021-07-03T09:03:19",
        "slug": "seri-covid-19-artikel-2-sebelum-buka-sekolah-apa-yang-harus-dikomunikasikan",
        "type": "post",
        "title": {
          "rendered": "Sebelum Buka Sekolah, Apa yang Harus Dikomunikasikan?"
        },
        "excerpt": {
          "rendered": "<p>Kebanyakan sekolah mengerti bahwa ada protokol kesehatan yang harus disiapkan sebelum membuka sekolah di masa pandemi. Tapi dari berbagai input yang disampaikan oleh guru, murid, orang tua maupun administrator sekolah, tidak ada pedoman bagaimana mengkomunikasikan pembukaan sekolah dan perilaku seperti apa yang sekolah minta dari para guru, siswa dan orang tua murid.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1642,
        "date_gmt": "2021-02-20T05:35:56",
        "modified_gmt": "2021-04-07T10:24:29",
        "slug": "infrastruktur-apa-yang-perlu-disiapkan-sekolah-new-normal",
        "type": "post",
        "title": {
          "rendered": "Infrastruktur Apa yang Perlu Disiapkan Sekolah New Normal?"
        },
        "excerpt": {
          "rendered": "<p>Di satu titik, sekolah akan tatap muka lagi. Berapa lama lagi, kita belum tahu. Tapi sekolah perlu menyiapkan infrastruktur new normal mulai sekarang agar ketika nanti sekolah dibuka, semua pihak sudah mengerti apa yang harus dilakukan.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1612,
        "date_gmt": "2021-01-14T02:21:49",
        "modified_gmt": "2021-03-02T04:52:49",
        "slug": "pertimbangan-untuk-langkah-langkah-kesehatan-masyarakat-terkait-sekolah-dalam-konteks-covid-19",
        "type": "post",
        "title": {
          "rendered": "Pertimbangan untuk Langkah-Langkah Kesehatan Masyarakat Terkait Sekolah dalam Konteks COVID-19"
        },
        "excerpt": {
          "rendered": "",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1550,
        "date_gmt": "2020-12-07T14:27:32",
        "modified_gmt": "2021-03-02T04:52:56",
        "slug": "panduan-perawatan-di-rumah-untuk-suspek-dan-positif-covid-19-dengan-gejala-ringan-atau-tanpa-gejala",
        "type": "post",
        "title": {
          "rendered": "Panduan Perawatan di Rumah untuk Suspek dan Positif- COVID-19 dengan Gejala Ringan atau Tanpa Gejala"
        },
        "excerpt": {
          "rendered": "<p>Proses hasil PCR yang lambat, makin banyaknya kasus positif sehingga RS hanya mampu melayani pasien dengan gejala sedang/berat, sehingga isolasi mandiri adalah pilihan buat pasien positif dengan gejala ringan atau untuk suspek COVID-19. Panduan ini diharapkan dapat membantu perawatan mandiri.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1522,
        "date_gmt": "2020-11-01T02:49:47",
        "modified_gmt": "2021-03-02T04:53:06",
        "slug": "isolasi-mandiri-untuk-ibu-menyusui",
        "type": "post",
        "title": {
          "rendered": "Isolasi Mandiri untuk Ibu Menyusui"
        },
        "excerpt": {
          "rendered": "<p>Rangkuman panduan yang harus dilakukan jika ibu menyusui terpapar COVID-19.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1284,
        "date_gmt": "2020-06-15T15:45:44",
        "modified_gmt": "2021-03-02T04:53:16",
        "slug": "mpasi-anak-dalam-kondisi-darurat",
        "type": "post",
        "title": {
          "rendered": "MPASI Anak dalam Kondisi Darurat"
        },
        "excerpt": {
          "rendered": "<p>Menjaga asupan gizi anak penting di tengah pandemi dan mudah dilakukan dengan memberikan Menu 4 Bintang.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1211,
        "date_gmt": "2020-04-30T08:49:24",
        "modified_gmt": "2021-03-02T04:53:24",
        "slug": "menyikapi-kedukaan-dan-kehilangan-saat-pandemi",
        "type": "post",
        "title": {
          "rendered": "Menyikapi Kedukaan dan Kehilangan Saat Pandemi"
        },
        "excerpt": {
          "rendered": "<p>Kehilangan orang tercinta bukan hal yang mudah dihadapi, apalagi di tengah pandemi. Dalam situasi sulit ini, bila memungkinkan, gunakan Internet dan solusi virtual lainnya untuk menjaga silaturahmi dengan keluarga dan teman untuk berbagi rasa kehilangan, serta untuk melaksanakan keinginan terakhir almarhum/almarhumah.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1177,
        "date_gmt": "2020-04-27T07:33:49",
        "modified_gmt": "2021-03-02T04:53:36",
        "slug": "imunisasi-rutin-selama-pandemi-covid-19",
        "type": "post",
        "title": {
          "rendered": "Imunisasi Rutin Selama Pandemi COVID-19"
        },
        "excerpt": {
          "rendered": "<p>Panduan untuk imunisasi rutin selama adanya pandemi COVID-19.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1171,
        "date_gmt": "2020-04-26T13:53:08",
        "modified_gmt": "2021-03-02T04:53:43",
        "slug": "panduan-menerima-dan-memberikan-bantuan-sosial-%ef%bb%bf",
        "type": "post",
        "title": {
          "rendered": "Panduan Menerima dan Memberikan Bantuan Sosial ﻿"
        },
        "excerpt": {
          "rendered": "<p>Saat ini, pembagian bantuan sosial, baik berupa paket sembako, uang, maupun keduanya, sudah banyak berlangsung. Hal ini adalah inisiatif yang baik untuk membantu masyarakat yang membutuhkan di tengah wabah COVID-19. Namun, ada beberapa hal yang perlu dipersiapkan oleh pemberi dan penerima bantuan.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1079,
        "date_gmt": "2020-04-15T05:47:19",
        "modified_gmt": "2021-03-02T04:53:52",
        "slug": "himbauan-untuk-vaksinasi",
        "type": "post",
        "title": {
          "rendered": "Himbauan untuk Vaksinasi"
        },
        "excerpt": {
          "rendered": "<p>Dokumen terkait himbauan untuk pelaksanaan vaksinasi anak selama pandemi COVID-19.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1063,
        "date_gmt": "2020-04-14T13:15:05",
        "modified_gmt": "2021-03-02T04:54:09",
        "slug": "tips-siaga-bagi-pasangan-yang-menunggu-kelahiran",
        "type": "post",
        "title": {
          "rendered": "Tips Siaga Bagi Pasangan yang Menunggu Kelahiran"
        },
        "excerpt": {
          "rendered": "<p>Lebih dari 200 juta wanita hamil di seluruh dunia berpotensi terinfeksi coronavirus. Beberapa penelitian mulai menguak bahaya virus SARS-CoV-2 ini pada wanita hamil dan bayinya, namun hasilnya&#8230;</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 969,
        "date_gmt": "2020-04-07T10:47:14",
        "modified_gmt": "2021-03-02T04:54:17",
        "slug": "seputar-penghantaran-dan-atau-penerimaan-gofood-gosend",
        "type": "post",
        "title": {
          "rendered": "Panduan Higienis Pengiriman dan/atau Penerimaan Barang/Makanan saat Pandemi"
        },
        "excerpt": {
          "rendered": "<p>Panduan apa yang sebaiknya dilakukan saat menerima atau mengirim barang melalui ojek online.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 962,
        "date_gmt": "2020-04-07T04:31:11",
        "modified_gmt": "2021-03-02T04:54:24",
        "slug": "rt-dan-desa-mandiri-lawan-korona",
        "type": "post",
        "title": {
          "rendered": "RT dan Desa Mandiri Lawan Korona"
        },
        "excerpt": {
          "rendered": "<p>Simak tulisan berikut ini untuk mengetahui panduan bagi RT dan desa dalam melawan koronavirus.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 960,
        "date_gmt": "2020-04-06T14:30:12",
        "modified_gmt": "2021-03-02T04:54:32",
        "slug": "prosedur-mendirikan-pusat-karantina-di-luar-fasilitas-kesehatan",
        "type": "post",
        "title": {
          "rendered": "Prosedur Mendirikan Pusat Karantina Di Luar Fasilitas Kesehatan"
        },
        "excerpt": {
          "rendered": "<p>Saat jumlah kasus positif COVID-19 meningkat sangat drastis melebihi kemampuan rumah sakit untuk merawat penderita, maka pemerintah setempat&#8230;.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 850,
        "date_gmt": "2020-03-31T09:59:48",
        "modified_gmt": "2021-03-02T04:54:40",
        "slug": "panduan-membuat-masker-kain-sendiri",
        "type": "post",
        "title": {
          "rendered": "Panduan Membuat Masker Kain Sendiri"
        },
        "excerpt": {
          "rendered": "<p>Saat ini, masker sulit ditemukan. Kalau pun tersedia, masker bedah lebih dibutuhkan oleh tenaga medis daripada masyarakat umum. Tapi jangan khawatir, untuk melindungi diri sendiri dan orang di sekitar, kita bisa, kok, membuat masker sendiri untuk kita dan keluarga. Simak tulisan berikut ini. </p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 846,
        "date_gmt": "2020-03-31T05:20:53",
        "modified_gmt": "2021-03-02T04:54:49",
        "slug": "panduan-menjaga-diri-dan-orang-lain-bila-terlanjur-mudik",
        "type": "post",
        "title": {
          "rendered": "Panduan Menjaga Diri dan Orang Lain Bila Terlanjur Mudik"
        },
        "excerpt": {
          "rendered": "<p>Bepergian ke luar kota, apalagi ke luar negeri, sangat tidak dianjurkan di tengah wabah. Namun, jika terlanjur mudik, ada hal-hal yang perlu dilakukan untuk menjaga diri dan orang di sekitar kita.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 715,
        "date_gmt": "2020-03-21T18:12:47",
        "modified_gmt": "2021-03-02T04:54:57",
        "slug": "panduan-penanganan-jenazah-terduga-kemungkinan-terkonfirmasi-covid-19",
        "type": "post",
        "title": {
          "rendered": "Panduan Penanganan Jenazah Terduga / Kemungkinan / Terkonfirmasi COVID-19"
        },
        "excerpt": {
          "rendered": "<p>Ikuti panduan ini untuk penanganan jenazah Terduga atau Positif COVID-19</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 563,
        "date_gmt": "2020-03-17T09:27:09",
        "modified_gmt": "2021-03-02T04:55:06",
        "slug": "panduan-naik-ojek-motor-mobil-untuk-pengemudi-dan-penumpang",
        "type": "post",
        "title": {
          "rendered": "Panduan Naik Ojek Motor/Mobil untuk Pengemudi dan Penumpang"
        },
        "excerpt": {
          "rendered": "<p>Ikuti panduan ini yuk untuk berkendara aman dengan Ojek Motor/Mobil di masa pandemi COVID-19 ini.</p>\n",
          "protected": false
        },
        "author": 2
      }
    ]
  }
}
