{
  "pageProps": {
    "category": {
      "id": 44,
      "count": 64,
      "description": "Bacaan penting dan analisa penting Covid-19",
      "name": "Bacaan Pilihan",
      "slug": "bacaan"
    },
    "posts": [
      {
        "id": 2148,
        "date_gmt": "2021-09-02T13:27:36",
        "modified_gmt": "2021-09-02T13:28:54",
        "slug": "pengalaman-karantina-mandiri-sepulang-dari-as",
        "type": "post",
        "title": {
          "rendered": "Pengalaman Karantina Mandiri Sepulang dari AS"
        },
        "excerpt": {
          "rendered": "<p>Akhir Juli lalu, saya dan suami berangkat ke AS setelah menunda beberapa bulan karena pandemi. Kami di sana selama dua minggu dan kembali ke Jakarta pada 5 Agustus 2021. Untuk itu, saya segera melengkapi diri dengan informasi mengenai tahapan yang harus dilewati saat pulang, termasuk keharusan menjalani karantina selama delapan hari.&nbsp; Begitu keluar dari pesawat, [&hellip;]</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1970,
        "date_gmt": "2021-06-30T01:56:00",
        "modified_gmt": "2021-06-30T01:56:15",
        "slug": "kalasuba-lockdown-vastraharan-drupadi",
        "type": "post",
        "title": {
          "rendered": "Kalasuba: Lockdown &#038; Vastraharan Drupadi"
        },
        "excerpt": {
          "rendered": "<p>oleh Sidrotun Naim28/6/2021 Vastraharan Punya suami lima Pandawa, harusnya hidup Drupadi serba enak. Lho, punya lima suami? Ya, begitulah versi asli Mahabharata, di versi wayang Jawa diperhalus bahwa dia hanya istri dari Yudistira, Pandawa tertua. Kurawa yang dipimpin Duryudana selalu ingin menjadi pewaris sah Hastinapura. Maka Hastinapura dibagi menjadi dua. Pandawa yang harus keluar, dengan [&hellip;]</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1925,
        "date_gmt": "2021-05-11T00:36:26",
        "modified_gmt": "2021-05-11T00:37:06",
        "slug": "ketika-harus-menyampaikan-kabar-buruk",
        "type": "post",
        "title": {
          "rendered": "Ketika Harus Menyampaikan Kabar Buruk"
        },
        "excerpt": {
          "rendered": "<p>Bagaimana mengkomunikasikan kabar buruk? Artikel ini membahas bagaimana berkomunikasi dengan empati terutama dalam konteks pandemi COVID-19.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1658,
        "date_gmt": "2021-02-27T02:25:18",
        "modified_gmt": "2021-03-01T02:11:05",
        "slug": "seri-penyintas-covid-19-klaster-keluarga-yang-masih-berjuang-untuk-pulih",
        "type": "post",
        "title": {
          "rendered": "Seri Penyintas COVID-19: Klaster Keluarga Yang Masih Berjuang Untuk Pulih"
        },
        "excerpt": {
          "rendered": "<p>Saya ingin membagi pengalaman kami sekeluarga untuk menjadi penyemangat teman-teman yang orang tua dan keluarganya terkena COVID-19. Kisah oleh @anindya_hafsa</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1638,
        "date_gmt": "2021-02-17T01:34:49",
        "modified_gmt": "2021-03-01T02:11:15",
        "slug": "kisahku-dan-covid-19",
        "type": "post",
        "title": {
          "rendered": "Seri Penyintas COVID-19: Kisahku dan COVID-19"
        },
        "excerpt": {
          "rendered": "<p>Penulis merasakan gejala yang mirip flu setelah pulang dari Bandung mengunjungi keluarganya. Ternyata penulis positif dan  hampir seluruh keluarganya terinfeksi COVID-19. Penulis menceritakan perjalanan perawatannya selama di RS.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1629,
        "date_gmt": "2021-02-03T08:03:57",
        "modified_gmt": "2021-03-01T02:11:24",
        "slug": "satu-tahun-evakuasi-dari-wuhan",
        "type": "post",
        "title": {
          "rendered": "Satu Tahun Evakuasi dari Wuhan"
        },
        "excerpt": {
          "rendered": "<p>Genap satu tahun penulis dipulangkan dari Wuhan bersama WNI lainnya. Penulis menceritakan kilas balik evakuasi mereka dari Wuhan, Tiongkok. Satu tahun berselang, Wuhan sebagai episentrum pertama COVID-19 telah kembali normal, sedangkan Indonesia masih berjuang untuk mengatasi pandemi ini. Ia mengajak Indonesia untuk menyontoh kegigihan Wuhan bersusah-susah dahulu agar kita bisa lebih cepat keluar dari pandemi.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1627,
        "date_gmt": "2021-02-03T08:02:45",
        "modified_gmt": "2021-03-01T02:11:34",
        "slug": "pengalaman-penderita-diabetes-terinfeksi-covid-19",
        "type": "post",
        "title": {
          "rendered": "Seri Penyintas COVID-19: Pengalaman Penderita Diabetes Terinfeksi COVID-19"
        },
        "excerpt": {
          "rendered": "<p>Setelah bertugas sebagai panitia KPPS (yang menyiapkan Pilkada di TPS masing-masing), penulis mengalami demam tinggi. Hasil rapid test non-reaktif, dan diagnosa pertama adalah typhus. Setelah beberapa hari di RS, kondisi tidak membaik dan hasil rontgen paru-paru menunjukkan infeksi COVID-19 yang divalidasi dengan hasil swab PCR positif.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1616,
        "date_gmt": "2021-01-24T04:07:21",
        "modified_gmt": "2021-03-01T02:11:42",
        "slug": "seri-penyintas-covid-19-saya-penyintas-dan-mengalami-long-covid",
        "type": "post",
        "title": {
          "rendered": "Seri Penyintas COVID-19: Saya Penyintas dan Mengalami Long COVID"
        },
        "excerpt": {
          "rendered": "<p>Cerita Juno, penyintas COVID-19 yang mengalami gejala Long COVID.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1606,
        "date_gmt": "2021-01-08T07:19:31",
        "modified_gmt": "2021-03-01T02:11:52",
        "slug": "catatan-penyintas-covid-19-bernatal-di-wisma-atlit",
        "type": "post",
        "title": {
          "rendered": "Catatan Penyintas COVID-19: Bernatal di Wisma Atlit"
        },
        "excerpt": {
          "rendered": "<p>Ketika berkumpul bersama keluarga, protokol kesehatan sering terlupakan dan menjadi celah penularan COVID-19. Penulis menceritakan kronologi terpaparnya dia dan keluarganya oleh COVID-19 sehingga merayakan Natal di Wisma Atlit</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1568,
        "date_gmt": "2020-12-31T03:24:38",
        "modified_gmt": "2021-03-01T02:12:01",
        "slug": "anak-kami-tertular-covid-19-di-sekolah-bagian-2",
        "type": "post",
        "title": {
          "rendered": "Seri Penyintas COVID-19: Anak Kami Tertular COVID-19 di Sekolah &#8211; bagian 2"
        },
        "excerpt": {
          "rendered": "<p>Kondisi COVID-19 di UK saat ini, walaupun lockdown diberlakukan namun sekolah  tetap dibuka. Dan tanpa kewajiban menggunakan masker bagi siswa dan guru</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1565,
        "date_gmt": "2020-12-31T03:21:59",
        "modified_gmt": "2021-03-01T02:12:12",
        "slug": "anak-kami-tertular-covid-19-di-sekolah-bagian-1",
        "type": "post",
        "title": {
          "rendered": "Seri Penyintas COVID-19: Anak Kami Tertular COVID-19 di Sekolah &#8211; bagian 1"
        },
        "excerpt": {
          "rendered": "<p>Benarkah sekolah sudah aman untuk dibuka? Bagaimana jika ada yang positif di sekolah?</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1536,
        "date_gmt": "2020-11-17T06:49:03",
        "modified_gmt": "2021-03-01T02:12:20",
        "slug": "seri-penyintas-covid-19-kami-positif-tapi-tidak-masuk-ke-data",
        "type": "post",
        "title": {
          "rendered": "Seri Penyintas COVID-19: Kami Positif Tapi Tidak Masuk ke Data"
        },
        "excerpt": {
          "rendered": "<p>Penulis dan keluarganya bergejala ringan tapi baru terbukti positif COVID-19 setelah swab kedua. Ia juga mempertanyakan kenapa data kasus positif tidak bertambah padahal keluarganya positif.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1498,
        "date_gmt": "2020-10-16T06:57:14",
        "modified_gmt": "2021-03-01T02:12:32",
        "slug": "seri-penyintas-covid-19-dua-kali-terpapar-dengan-gejala-yang-berbeda",
        "type": "post",
        "title": {
          "rendered": "Seri Penyintas COVID-19: Dua Kali Terpapar dengan Gejala yang Berbeda"
        },
        "excerpt": {
          "rendered": "<p>Bulan Maret 2020, penulis terpapar COVID-19 dengan gejala kritis. Di awal Juli 2020, penulis kembali terkena COVID-19 tapi tanpa gejala, beserta dengan istri dan anaknya. Cerita ini adalah contoh bagaimana seseorang bisa terkena COVID-19 lebih dari sekali (reinfeksi). </p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1487,
        "date_gmt": "2020-10-01T14:40:13",
        "modified_gmt": "2021-07-03T09:03:36",
        "slug": "kumpulan-cerita-pengawal-pagebluk",
        "type": "post",
        "title": {
          "rendered": "Kumpulan Cerita Pengawal Pagebluk"
        },
        "excerpt": {
          "rendered": "<p>Agar masyarakat umum lebih memahami kondisi pandemi di lapangan, kami mengumpulkan narasi orang pertama dari tenaga kesehatan, lab, dan pihak lain yang turut mengawal penanganan COVID-19 di berbagai daerah di Indonesia.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1483,
        "date_gmt": "2020-10-01T14:18:31",
        "modified_gmt": "2021-03-01T02:14:59",
        "slug": "seri-pengawal-pagebluk-pasien-mendadak-kritis-atau-meninggal-testing-lemah-dan-warga-abai",
        "type": "post",
        "title": {
          "rendered": "Seri Pengawal Pagebluk: Pasien Mendadak Kritis atau Meninggal, Testing Lemah, dan Warga Abai"
        },
        "excerpt": {
          "rendered": "<p>Jumlah kasus positif rendah bukan berarti sebuah daerah aman dari COVID-19. Seorang dokter di Provinsi Lampung melaporkan beberapa pasien yang ternyata positif COVID-19, mendadak kritis, atau bahkan meninggal dalam waktu singkat ketika sudah berada di RS rujukan COVID-19. Minimnya testing membuat warga tidak was-was dan akhirnya abai.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1477,
        "date_gmt": "2020-09-27T03:11:02",
        "modified_gmt": "2021-03-01T02:15:08",
        "slug": "cerita-covid-19-art-positif-meski-berkegiatan-di-sekitar-rumah-saja",
        "type": "post",
        "title": {
          "rendered": "Cerita COVID-19: ART Positif Meski Berkegiatan di Sekitar Rumah Saja"
        },
        "excerpt": {
          "rendered": "<p>Asisten rumah tangga (ART) penulis positif COVID-19 meski tinggal di rumah saja dan hanya keluar untuk keperluan tertentu. Sementara itu, ART lainnya meninggal dunia dua hari sejak gejala awal muncul. Pengalaman ini adalah contoh bagaimana rumah bisa menjadi klaster penyebaran.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1474,
        "date_gmt": "2020-09-24T06:25:43",
        "modified_gmt": "2021-03-01T02:15:18",
        "slug": "seri-penyintas-covid-19-perjuangan-mencari-rumah-sakit-dan-melewati-masa-kritis",
        "type": "post",
        "title": {
          "rendered": "Seri Penyintas COVID-19: Perjuangan Mencari Rumah Sakit dan Melewati Masa Kritis"
        },
        "excerpt": {
          "rendered": "<p>Seorang penyintas di Surabaya mengalami COVID-19 sekaligus demam berdarah. Selama berhari-hari, kondisinya kritis dan nyawanya bergantung pada ventilator. Ia selamat tapi kehilangan nenek tercinta akibat COVID-19.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1460,
        "date_gmt": "2020-08-31T15:25:25",
        "modified_gmt": "2021-03-01T02:15:27",
        "slug": "benarkah-virus-mutasi-d614g-10x-lebih-mudah-menginfeksi",
        "type": "post",
        "title": {
          "rendered": "Benarkah Virus Mutasi D614G 10x Lebih Mudah Menginfeksi?"
        },
        "excerpt": {
          "rendered": "<p>Menanggapi klaim Dr Noor Hisham bahwa virus mutasi D614G 10x lebih menular, KawalCOVID19.id memberikan analisis dan posisi kami terhadap isu ini.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1457,
        "date_gmt": "2020-08-28T14:00:57",
        "modified_gmt": "2021-03-01T02:15:37",
        "slug": "seri-garda-terdepan-suka-duka-merawat-pasien-covid-19-di-rs-suyoto",
        "type": "post",
        "title": {
          "rendered": "Seri Pengawal Pagebluk: Suka Duka Merawat Pasien COVID-19 di RS Suyoto"
        },
        "excerpt": {
          "rendered": "<p>Seorang dokter relawan di Jakarta menggambarkan situasi perawatan COVID-19 di lapangan, termasuk prosedur memakai APD setiap kali memeriksa pasien hingga kematian pasien akibat penyakit penyerta, bahkan pada pasien remaja.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1409,
        "date_gmt": "2020-08-24T08:24:50",
        "modified_gmt": "2021-03-01T02:15:45",
        "slug": "seri-garda-terdepan-kendala-lapangan-analis-medis-di-kalimantan-selatan",
        "type": "post",
        "title": {
          "rendered": "Seri Pengawal Pagebluk: Kendala Lapangan Analis Medis di Kalimantan Selatan"
        },
        "excerpt": {
          "rendered": "<p>Seorang analis kesehatan di Kalimantan Selatan memaparkan faktor-faktor yang menyebabkan hasil swab lama dipublikasikan, mulai dari kekurangan SDM, kecilnya kapasitas alat tes, hingga data yang tidak sinkron.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1399,
        "date_gmt": "2020-08-15T14:52:50",
        "modified_gmt": "2021-03-01T02:15:53",
        "slug": "seri-penyintas-covid-19-ibu-dan-anak-sesama-dokter-tertular-dan-sembuh-dari-covid-19",
        "type": "post",
        "title": {
          "rendered": "Seri Penyintas COVID-19: Ibu dan Anak Sesama Dokter Tertular dan Sembuh dari COVID-19"
        },
        "excerpt": {
          "rendered": "<p>Seorang dokter gigi (ibu) dan dokter umum (anak) terpapar COVID-19 di fasilitas kesehatan pada waktu yang berlainan. Meski gejalanya ringan, masa isolasi terasa berat. Belum lagi tuduhan yang diterima dari keluarga pasien bahwa COVID-19 ini konspirasi RS.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1390,
        "date_gmt": "2020-08-04T13:00:40",
        "modified_gmt": "2021-03-01T02:16:01",
        "slug": "seri-penyintas-covid-19-papaku-terus-sesak-napas-meski-sudah-dinyatakan-sembuh",
        "type": "post",
        "title": {
          "rendered": "Seri Penyintas COVID-19: Papaku Terus Sesak Napas Meski Sudah Dinyatakan Sembuh"
        },
        "excerpt": {
          "rendered": "<p>Lima minggu setelah dinyatakan sembuh dari COVID-19, ayah dari penulis masih terus sesak napas dan memakai masker oksigen di rumah.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1388,
        "date_gmt": "2020-08-04T12:58:01",
        "modified_gmt": "2021-03-01T02:16:08",
        "slug": "seri-penyintas-covid-19-dari-kehilangan-kerabat-hingga-donor-plasma-darah",
        "type": "post",
        "title": {
          "rendered": "Seri Penyintas COVID-19: Dari Kehilangan Kerabat Hingga Donor Plasma Darah"
        },
        "excerpt": {
          "rendered": "<p>Belum usai berduka setelah kerabatnya meninggal dunia akibat COVID-19, penulis terkonfirmasi positif. Dengan kesabaran selama perawatan di ruang isolasi RS, ia pun sembuh dan telah mendonorkan plasma darah untuk membantu pasien COVID-19 lain dengan kondisi kritis.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1383,
        "date_gmt": "2020-07-28T14:57:34",
        "modified_gmt": "2021-03-01T02:49:32",
        "slug": "seri-penyintas-covid-19-ketidakjujuran-pasien-icu-dan-keluarganya-membuat-saya-terpapar-dan-diintubasi",
        "type": "post",
        "title": {
          "rendered": "Seri Penyintas COVID-19: Ketidakjujuran Pasien ICU dan Keluarganya Membuat Saya Terpapar dan Diintubasi"
        },
        "excerpt": {
          "rendered": "<p>Akibat kebohongan satu orang, sedikitnya tujuh orang tim medis dan non-medis di RS rujukan COVID-19 di Yogyakarta terinfeksi. </p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1365,
        "date_gmt": "2020-07-23T04:53:42",
        "modified_gmt": "2021-03-01T08:12:34",
        "slug": "kumpulan-cerita-penyintas-covid-19",
        "type": "post",
        "title": {
          "rendered": "Kumpulan Cerita Penyintas COVID-19"
        },
        "excerpt": {
          "rendered": "<p>Berikut kisah penyintas COVID-19 yang berhasil kami kumpulkan selama ini.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1359,
        "date_gmt": "2020-07-22T14:49:14",
        "modified_gmt": "2021-03-01T02:49:48",
        "slug": "seri-penyintas-covid-19-tiga-bulan-bergumul-dengan-covid-19",
        "type": "post",
        "title": {
          "rendered": "Seri Penyintas COVID-19: Tiga Bulan Bergumul dengan COVID-19"
        },
        "excerpt": {
          "rendered": "<p>Sepulang dari Inggris, penulis mengalami anosmia (kehilangan indera penciuman). Selama tiga bulan lamanya, ia dinyatakan positif COVID-19 dengan total 10 tes swab.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1356,
        "date_gmt": "2020-07-22T14:23:01",
        "modified_gmt": "2021-03-01T02:49:55",
        "slug": "gambaran-penggunaan-ventilator-bagi-pasien-covid-19-kritis",
        "type": "post",
        "title": {
          "rendered": "Gambaran Penggunaan Ventilator bagi Pasien COVID-19 Kritis"
        },
        "excerpt": {
          "rendered": "<p>Inilah ilustrasi penggunaan ventilator bagi pasien COVID-19 dengan kondisi kritis.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1354,
        "date_gmt": "2020-07-22T14:19:11",
        "modified_gmt": "2021-03-01T02:50:03",
        "slug": "seri-penyintas-covid-19-virus-tidak-kenal-profesi-dan-usia",
        "type": "post",
        "title": {
          "rendered": "Seri Penyintas COVID-19: Virus Tidak Kenal Profesi dan Usia"
        },
        "excerpt": {
          "rendered": "<p>Seorang dokter di usia produktif di Bali terpapar COVID-19 dengan kondisi kritis. Kisahnya menyadarkan bahwa seseorang yang tahu betul soal dunia medis dan berusia relatif muda tidak serta merta kebal dari virus.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1349,
        "date_gmt": "2020-07-21T03:33:53",
        "modified_gmt": "2021-03-01T02:50:10",
        "slug": "kisah-dania-berjuang-sembuh-dengan-diagnosa-tidak-pasti",
        "type": "post",
        "title": {
          "rendered": "Kisah Dania: Berjuang Sembuh dengan Diagnosa Tidak Pasti"
        },
        "excerpt": {
          "rendered": "<p>Benarkah Rumah Sakit mempositifkan banyak orang demi mendapat insentif pemerintah?</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1347,
        "date_gmt": "2020-07-21T03:25:38",
        "modified_gmt": "2021-03-01T02:50:21",
        "slug": "kisah-mila-perjuangan-di-ruang-isolasi-dengan-status-pdp",
        "type": "post",
        "title": {
          "rendered": "Kisah Mila*: Perjuangan di Ruang Isolasi dengan Status PDP"
        },
        "excerpt": {
          "rendered": "<p>Benarkah Rumah Sakit mempositifkan banyak orang demi mendapat insentif pemerintah?</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1278,
        "date_gmt": "2020-06-12T12:47:02",
        "modified_gmt": "2021-03-01T02:50:31",
        "slug": "edukasi-benarkah-otg-tidak-menular",
        "type": "post",
        "title": {
          "rendered": "[EDUKASI] Benarkah OTG tidak menular?"
        },
        "excerpt": {
          "rendered": "<p>Meluruskan kesimpangsiuran tentang risiko OTG menularkan COVID-19</p>\n",
          "protected": false
        },
        "author": 62
      },
      {
        "id": 1225,
        "date_gmt": "2020-05-08T07:38:04",
        "modified_gmt": "2021-03-01T02:50:40",
        "slug": "seri-garda-terdepan-bertugas-sebagai-dokter-di-rs-darurat-covid-19",
        "type": "post",
        "title": {
          "rendered": "Seri Pengawal Pagebluk: Bertugas sebagai Dokter di RS Darurat COVID-19"
        },
        "excerpt": {
          "rendered": "<p>Seorang dokter yang ditugaskan di Rumah Sakit Darurat COVID-19 Wisma Atlet Kemayoran, Jakarta, menceritakan kesehariannya selama dua minggu pertama bertugas.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1192,
        "date_gmt": "2020-04-28T12:08:52",
        "modified_gmt": "2021-03-01T02:50:50",
        "slug": "seri-penyintas-covid-19-tidak-bergejala-serius-bukan-berarti-tidak-terinfeksi",
        "type": "post",
        "title": {
          "rendered": "Seri Penyintas COVID-19: Tidak Bergejala Serius Bukan Berarti Tidak Terinfeksi"
        },
        "excerpt": {
          "rendered": "<p>Seorang penyintas COVID-19 tanpa gejala serius menceritakan hari-hari selama isolasi dan pesan pentingnya bagi pasien lain.  </p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1190,
        "date_gmt": "2020-04-28T12:08:07",
        "modified_gmt": "2021-03-01T02:51:01",
        "slug": "bertahan-saja-sudah-cukup",
        "type": "post",
        "title": {
          "rendered": "Bertahan Saja Sudah Cukup"
        },
        "excerpt": {
          "rendered": "<p>Cerita dan saran bagaimana mengatasi rasa cemas karena bipolar disorder yang dapat diterapkan untuk mengatasi rasa cemas karena pandemi.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1181,
        "date_gmt": "2020-04-27T07:46:27",
        "modified_gmt": "2021-03-01T02:51:10",
        "slug": "tiga-hal-yang-penting-untuk-kebijakan-mitigasi-wabah-covid-19-berdasarkan-data-indonesia",
        "type": "post",
        "title": {
          "rendered": "Tiga Hal yang Penting untuk Kebijakan Mitigasi Wabah COVID-19 Berdasarkan Data Indonesia"
        },
        "excerpt": {
          "rendered": "<p>Mari simak pola-pola pasien COVID-19 di Indonesia berdasarkan data dan bagaimana kebijakan mitigasi yang tepat.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1179,
        "date_gmt": "2020-04-27T07:35:01",
        "modified_gmt": "2021-03-01T02:51:20",
        "slug": "belajar-dari-singapura-no-one-is-safe-until-everyone-is-safe",
        "type": "post",
        "title": {
          "rendered": "Belajar dari Singapura: No One is Safe Until Everyone is Safe"
        },
        "excerpt": {
          "rendered": "<p>Kasus baru COVID-19 di Singapura melonjak di bulan April 2020, jumlah penambahan kasus harian di Singapura. Apa yang terjadi di Singapura dalam beberapa minggu terakhir?</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1120,
        "date_gmt": "2020-04-20T03:20:49",
        "modified_gmt": "2021-03-01T02:51:37",
        "slug": "indonesia-mandiri-masker",
        "type": "post",
        "title": {
          "rendered": "Indonesia Mandiri Masker"
        },
        "excerpt": {
          "rendered": "<p>Yuk simak data penelitian faktual dan tervalidasi terkait manfaat masker kain buatan sendiri untuk mengurangi penularan virus penyebab COVID-19. </p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1094,
        "date_gmt": "2020-04-19T11:15:40",
        "modified_gmt": "2021-03-01T02:51:46",
        "slug": "saya-sudah-kehilangan-ayah-beberapa-anggota-keluarga-besar-dan-11-teman",
        "type": "post",
        "title": {
          "rendered": "Saya Sudah Kehilangan Ayah, Beberapa Anggota Keluarga Besar, dan 11 Teman"
        },
        "excerpt": {
          "rendered": "<p>Kisah nyata dari relawan KawalCovid19.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1006,
        "date_gmt": "2020-04-10T08:11:49",
        "modified_gmt": "2021-03-02T04:47:32",
        "slug": "cerita-dari-jerman-pengalaman-dua-wni-di-bochum-dan-frankfurt",
        "type": "post",
        "title": {
          "rendered": "Cerita dari Jerman: Pengalaman Dua WNI di Bochum dan Frankfurt"
        },
        "excerpt": {
          "rendered": "<p>Seorang pelajar dan seorang pekerja asal Indonesia yang tinggal di Jerman membagikan kisah penanganan pandemi oleh pemerintah Jerman</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 992,
        "date_gmt": "2020-04-10T08:07:59",
        "modified_gmt": "2021-03-02T04:47:39",
        "slug": "cerita-dari-taiwan-respons-tepat-taiwan-dalam-menghadapi-sars-cov-2-dan-covid-19",
        "type": "post",
        "title": {
          "rendered": "Cerita dari Taiwan: Respons Tepat Taiwan dalam Menghadapi SARS COV-2 dan COVID-19"
        },
        "excerpt": {
          "rendered": "<p>Taiwan disebut-sebut sebagai negara yang mengantisipasi penyebaran COVID-19 dengan baik sebelum wabah memburuk. Artikel ini merangkum hal-hal baik yang dilakukan oleh Taiwan dan yang dapat dijadikan contoh.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 966,
        "date_gmt": "2020-04-07T04:35:32",
        "modified_gmt": "2021-03-01T02:52:44",
        "slug": "tips-agar-tetap-positif-selama-bekerja-dari-rumah",
        "type": "post",
        "title": {
          "rendered": "Tips Agar Tetap Positif Selama Bekerja dari Rumah"
        },
        "excerpt": {
          "rendered": "<p>Jenuh bekerja dari rumah? Simak tips berikut bagi tenaga profesional dan pekerja lainnya agar pikiran tetap positif selama #DiRumahAja.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 964,
        "date_gmt": "2020-04-07T04:33:51",
        "modified_gmt": "2021-03-01T02:52:53",
        "slug": "bertahan-dari-hubungan-tidak-sehat-selama-pandemi-covid-19",
        "type": "post",
        "title": {
          "rendered": "Waspadai Kekerasan Dalam Rumah Tangga saat Isolasi Mandiri"
        },
        "excerpt": {
          "rendered": "<p>Himbauan #DiRumahAja membawa permasalahan tersendiri bagi mereka yang terlibat dalam hubungan tidak sehat (toxic relationship) di rumah. Jika Anda salah satunya, simak tips untuk mengendalikan situasi dan menyelamatkan diri.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 856,
        "date_gmt": "2020-04-01T05:16:29",
        "modified_gmt": "2021-03-01T02:53:01",
        "slug": "belajar-dari-vietnam",
        "type": "post",
        "title": {
          "rendered": "Belajar dari Vietnam"
        },
        "excerpt": {
          "rendered": "<p>Dengan sumber daya terbatas dibandingkan Korea Selatan, Vietnam mampu mengatasi pandemi dengan langkah-langkah cepat dan tepat.</p>\n",
          "protected": false
        },
        "author": 125
      },
      {
        "id": 839,
        "date_gmt": "2020-03-30T12:09:12",
        "modified_gmt": "2021-03-01T02:53:54",
        "slug": "polemik-penggunaan-vitamin-dan-suplemen-kesehatan",
        "type": "post",
        "title": {
          "rendered": "Polemik Penggunaan Vitamin dan Suplemen Kesehatan"
        },
        "excerpt": {
          "rendered": "<p> Di tengah-tengah wabah COVID-19, banyak informasi mengenai vitamin dan suplemen kesehatan yang kita terima baik dari pesan berantai WhatsApp, maupun nasihat dari teman-teman. Kami ingin Anda lebih mengerti tentang vitamin dan suplemen kesehatan, beserta penggunaannya bagi kesehatan Anda </p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 820,
        "date_gmt": "2020-03-29T04:45:03",
        "modified_gmt": "2021-03-01T02:53:45",
        "slug": "lockdown-bukan-berarti-ekonomi-berhenti-begini-caranya",
        "type": "post",
        "title": {
          "rendered": "Lockdown? Bukan berarti ekonomi berhenti. Begini caranya!"
        },
        "excerpt": {
          "rendered": "<p>New York sudah menerapkan lockdown. Kebijakan yang tadinya dikhawatirkan akan mengganggu stabilitas ekonomi ternyata hanya mengubah jenis mata pencaharian yang tersedia berkat dukungan dan intervensi pemerintah serta perusahaan-perusahaan besar.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 803,
        "date_gmt": "2020-03-28T15:33:36",
        "modified_gmt": "2021-03-01T02:54:02",
        "slug": "karantina-wilayah",
        "type": "post",
        "title": {
          "rendered": "Karantina Wilayah"
        },
        "excerpt": {
          "rendered": "<p>&#8230; a brief to the President</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 796,
        "date_gmt": "2020-03-28T02:21:59",
        "modified_gmt": "2021-03-01T02:54:11",
        "slug": "dari-de-epidemie-ke-wajah-abad-ini-menghadapi-wabah",
        "type": "post",
        "title": {
          "rendered": "Dari “De Epidemie” ke Wajah Abad ini Menghadapi Wabah"
        },
        "excerpt": {
          "rendered": "<p>COVID-19 bukan pandemi pertama yang Indonesia hadapi sepanjang sejarah. Seabad yang lalu, Hindia Belanda sebutan Indonesia di kala itu) mengalami Pandemi Flu. Artikel ini menelusuri fakta-fakta sejarah yang relevan dengan kondisi Indonesia di masa kini sebagai  pelajaran berharga.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 786,
        "date_gmt": "2020-03-27T09:08:54",
        "modified_gmt": "2021-03-01T02:54:22",
        "slug": "ingat-italia-4-minggu-di-depan-indonesia",
        "type": "post",
        "title": {
          "rendered": "Ingat Italia (4 Minggu di Depan Indonesia)"
        },
        "excerpt": {
          "rendered": "<p>Tulisan ini menjabarkan analisis tentang intervensi yang dilakukan pemerintah Italia untuk menekan laju jumlah kasus terkonfimasi positif COVID-19 di Italia dan dampaknya.</p>\n",
          "protected": false
        },
        "author": 125
      },
      {
        "id": 778,
        "date_gmt": "2020-03-26T12:32:20",
        "modified_gmt": "2021-03-01T02:59:39",
        "slug": "cerita-dari-filipina-menjalani-karantina-wilayah-lockdown-sebagai-diaspora",
        "type": "post",
        "title": {
          "rendered": "Cerita dari Filipina: Menjalani Karantina wilayah (lockdown) sebagai Diaspora"
        },
        "excerpt": {
          "rendered": "<p>Sepulang dari Indonesia dan tiba kembali di Filipina, koresponden kami disambut dengan karantina wilayah (lockdown) oleh pemerintah setempat. Simak pengalamannya menjalani hal tersebut sebagai diaspora Indonesia di Manila.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 764,
        "date_gmt": "2020-03-25T02:37:19",
        "modified_gmt": "2021-03-01T02:59:49",
        "slug": "catatan-dari-wuhan-part-1",
        "type": "post",
        "title": {
          "rendered": "Cerita dari Tiongkok: Catatan dari Wuhan"
        },
        "excerpt": {
          "rendered": "<p>Pengalaman tinggal di Wuhan, Cina di tengah pandemik COVID-19</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 745,
        "date_gmt": "2020-03-24T06:29:26",
        "modified_gmt": "2021-03-01T02:59:57",
        "slug": "cerita-dari-italia-a-cautionary-tale-peringatan-untuk-kita-semua",
        "type": "post",
        "title": {
          "rendered": "Cerita dari Italia: A Cautionary Tale (Peringatan untuk Kita Semua)"
        },
        "excerpt": {
          "rendered": "<p>Seorang mahasiswa S3 asal Indonesia membagikan pengalaman dan observasinya selama tinggal di Trieste, Italia di tengah pandemi COVID-19. Artikel ini penting untuk menjadi bahan renungan bagi masyarakat di Indonesia agar yang terjadi di Italia tidak perlu terulang di negara kita.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 698,
        "date_gmt": "2020-03-21T17:38:31",
        "modified_gmt": "2021-03-01T03:00:08",
        "slug": "mencegah-dan-menangani-stigma-sosial-seputar-covid-19",
        "type": "post",
        "title": {
          "rendered": "Mencegah dan Menangani Stigma Sosial Seputar COVID-19"
        },
        "excerpt": {
          "rendered": "<p>Pandemi COVID-19 muncul bersamaan dengan stigma sosial di tengah masyarakat. Namun, hal ini dapat dicegah dan ditangani bersama oleh individu maupun pihak-pihak terkait.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 679,
        "date_gmt": "2020-03-21T17:27:08",
        "modified_gmt": "2021-03-01T03:00:17",
        "slug": "cerita-dari-singapura-belajar-menjaga-diri-sendiri-dari-sekolah",
        "type": "post",
        "title": {
          "rendered": "Cerita dari Singapura: Belajar Menjaga Diri Sendiri dari Sekolah"
        },
        "excerpt": {
          "rendered": "<p>Dina Kharisma, seorang guru SD di Singapura, membagikan pengalamannya soal langkah-langkah yang dilakukan di sekolah ketika COVID-19 datang ke negeri singa tersebut.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 685,
        "date_gmt": "2020-03-21T17:25:08",
        "modified_gmt": "2021-03-01T03:00:25",
        "slug": "tips-menjaga-kesehatan-jiwa-saat-jaga-jarak",
        "type": "post",
        "title": {
          "rendered": "Tips Menjaga Kesehatan Jiwa Saat Jaga Jarak"
        },
        "excerpt": {
          "rendered": "<p>Tips untuk menjaga kesehatan jiwa pada saat masa karantina dan jaga jarak dulu.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 676,
        "date_gmt": "2020-03-21T17:22:38",
        "modified_gmt": "2021-03-01T03:00:36",
        "slug": "10-tips-meringankan-beban-tenaga-medis-di-lapangan",
        "type": "post",
        "title": {
          "rendered": "10 Tips Meringankan Beban Tenaga Medis di Lapangan"
        },
        "excerpt": {
          "rendered": "<p>Selain diam di rumah, bantu juga tenaga medis dengan melakukan hal-hal berikut ini.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 650,
        "date_gmt": "2020-03-21T04:51:36",
        "modified_gmt": "2021-03-01T03:00:45",
        "slug": "bagaimana-virus-corona-menyerang-tubuh",
        "type": "post",
        "title": {
          "rendered": "Bagaimana Virus Corona Menyerang Tubuh?"
        },
        "excerpt": {
          "rendered": "<p>Bagaimana SARS-CoV-2 menginfeksi dan menyerang tubuh, dan apa dampaknya pada tubuh selain paru-paru. </p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 628,
        "date_gmt": "2020-03-20T12:37:25",
        "modified_gmt": "2021-03-01T03:00:54",
        "slug": "vaksin-dan-covid-19",
        "type": "post",
        "title": {
          "rendered": "Vaksin dan COVID-19"
        },
        "excerpt": {
          "rendered": "<p>Vaksin COVID-19 sudah ada atau belum sih? Mari kita cari tahu kebenarannya.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 592,
        "date_gmt": "2020-03-18T12:43:56",
        "modified_gmt": "2021-03-01T03:01:10",
        "slug": "siapkan-anak-anda-menghadapi-wabah-koronavirus-covid-19",
        "type": "post",
        "title": {
          "rendered": "Siapkan Anak Anda Menghadapi Wabah Koronavirus (COVID-19)"
        },
        "excerpt": {
          "rendered": "<p>Checklist untuk orang tua agar dapat mempersiapkan anak menghadapi wabah COVID-19</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 594,
        "date_gmt": "2020-03-18T12:38:44",
        "modified_gmt": "2021-03-01T03:01:25",
        "slug": "wuhan-virus-korona-dan-kewaspadaan-kita",
        "type": "post",
        "title": {
          "rendered": "Wuhan, Virus Korona, dan Kewaspadaan Kita"
        },
        "excerpt": {
          "rendered": "<p>Bagaimana Cina meningkatkan kewaspadaan terhadap virus korona yang mewabah</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 548,
        "date_gmt": "2020-03-17T05:43:50",
        "modified_gmt": "2021-03-01T03:01:35",
        "slug": "dirjen-who-virus-covid-19-adalah-virus-yang-unik-dengan-karakteristik-yang-unik-pula",
        "type": "post",
        "title": {
          "rendered": "Dirjen WHO: ”Virus COVID-19 adalah Virus yang Unik dengan Karakteristik yang Unik Pula&#8221;"
        },
        "excerpt": {
          "rendered": "<p>Berikut penjelasan beberapa perbedaan karakteristik virus penyebab COVID-19 dan influenza.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 535,
        "date_gmt": "2020-03-16T13:32:39",
        "modified_gmt": "2021-03-01T03:01:45",
        "slug": "kegiatan-belajar-dan-bermain-di-rumah-untuk-anak-balita-dan-taman-kanak-kanak",
        "type": "post",
        "title": {
          "rendered": "Kegiatan Belajar dan Bermain di Rumah untuk Anak Balita dan Taman Kanak-Kanak"
        },
        "excerpt": {
          "rendered": "<p>Sekolah anak libur selama 2 minggu? Jangan panik! Kami sudah menyiapkan kegiatan yang dapat dilakukan bersama anak saat libur.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 524,
        "date_gmt": "2020-03-16T12:45:55",
        "modified_gmt": "2021-03-01T03:33:59",
        "slug": "siapkan-anak-anda-menghadapi-wabah-koronavirus",
        "type": "post",
        "title": {
          "rendered": "Siapkan Anak Anda Menghadapi Wabah Koronavirus"
        },
        "excerpt": {
          "rendered": "<p>Berbicara dengan Anak tentang COVID-19</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 349,
        "date_gmt": "2020-03-12T23:22:02",
        "modified_gmt": "2021-03-01T03:36:33",
        "slug": "cerita-dari-korea-selatan-pengalaman-ngantor-di-tengah-wabah",
        "type": "post",
        "title": {
          "rendered": "Cerita dari Korea Selatan: Pengalaman Ngantor di Tengah Wabah"
        },
        "excerpt": {
          "rendered": "<p>Pengaruh coronavirus terhadap kondisi kerja di Seoul, Korea.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 302,
        "date_gmt": "2020-03-10T04:27:20",
        "modified_gmt": "2021-03-01T03:36:42",
        "slug": "superspreader-setiap-orang-bisa-cegah-penularan-lebih-lanjut",
        "type": "post",
        "title": {
          "rendered": "Superspreader: Setiap Orang Bisa Cegah Penularan Lebih Lanjut"
        },
        "excerpt": {
          "rendered": "<p>Indonesia bisa belajar dari penyebaran luas COVID-19 di negara-negara lain yang bermula dari satu orang saja.</p>\n",
          "protected": false
        },
        "author": 82
      }
    ],
    "authors": {
      "2": {
        "id": 2,
        "name": "Kawal COVID-19",
        "description": "Tim administrator situs KawalCOVID19.id",
        "slug": "admin",
        "avatar_urls": {
          "24": "http://0.gravatar.com/avatar/662a7ed69e971f6fb58f2c0aff371674?s=24&d=retro&r=g",
          "48": "http://0.gravatar.com/avatar/662a7ed69e971f6fb58f2c0aff371674?s=48&d=retro&r=g",
          "96": "http://0.gravatar.com/avatar/662a7ed69e971f6fb58f2c0aff371674?s=96&d=retro&r=g"
        },
        "_links": {
          "self": [
            {
              "href": "http://18.141.204.243/index.php?rest_route=/wp/v2/users/2"
            }
          ],
          "collection": [
            {
              "href": "http://18.141.204.243/index.php?rest_route=/wp/v2/users"
            }
          ]
        }
      },
      "62": {
        "id": 62,
        "name": "Elina Ciptadi",
        "description": "",
        "slug": "elina-ciptadi",
        "avatar_urls": {
          "24": "http://1.gravatar.com/avatar/1ac374567d5d3d8fdf2879cb3460d537?s=24&d=retro&r=g",
          "48": "http://1.gravatar.com/avatar/1ac374567d5d3d8fdf2879cb3460d537?s=48&d=retro&r=g",
          "96": "http://1.gravatar.com/avatar/1ac374567d5d3d8fdf2879cb3460d537?s=96&d=retro&r=g"
        },
        "_links": {
          "self": [
            {
              "href": "http://18.141.204.243/index.php?rest_route=/wp/v2/users/62"
            }
          ],
          "collection": [
            {
              "href": "http://18.141.204.243/index.php?rest_route=/wp/v2/users"
            }
          ]
        }
      },
      "82": {
        "id": 82,
        "name": "Septian Hartono",
        "description": "Praktisi kesehatan dan peneliti MRI di salah satu rumah sakit umum di Singapura. Asisten Profesor di Duke-NUS Medical School.",
        "slug": "septian-hartono",
        "avatar_urls": {
          "24": "http://0.gravatar.com/avatar/3020050fbfb28276163e5b93c4dff917?s=24&d=retro&r=g",
          "48": "http://0.gravatar.com/avatar/3020050fbfb28276163e5b93c4dff917?s=48&d=retro&r=g",
          "96": "http://0.gravatar.com/avatar/3020050fbfb28276163e5b93c4dff917?s=96&d=retro&r=g"
        },
        "_links": {
          "self": [
            {
              "href": "http://18.141.204.243/index.php?rest_route=/wp/v2/users/82"
            }
          ],
          "collection": [
            {
              "href": "http://18.141.204.243/index.php?rest_route=/wp/v2/users"
            }
          ]
        }
      },
      "125": {
        "id": 125,
        "name": "Ruly Achdiat Santabrata",
        "description": "Seasoned Integration Architect / Data Architect at TIBCO Software, Ltd (tibco.com). Ruly helps many well known companies in complex integration and data projects covering Asia Pacific and Europe. Lives in London, England",
        "slug": "kangsantabrata",
        "avatar_urls": {
          "24": "http://1.gravatar.com/avatar/442a1d72b848e1a0cce32085d55c516d?s=24&d=retro&r=g",
          "48": "http://1.gravatar.com/avatar/442a1d72b848e1a0cce32085d55c516d?s=48&d=retro&r=g",
          "96": "http://1.gravatar.com/avatar/442a1d72b848e1a0cce32085d55c516d?s=96&d=retro&r=g"
        },
        "_links": {
          "self": [
            {
              "href": "http://18.141.204.243/index.php?rest_route=/wp/v2/users/125"
            }
          ],
          "collection": [
            {
              "href": "http://18.141.204.243/index.php?rest_route=/wp/v2/users"
            }
          ]
        }
      }
    }
  },
  "__N_SSG": true
}
