{
  "pageProps": {
    "category": {
      "id": 40,
      "count": 22,
      "description": "Panduan dan protokol terkait COVID-19",
      "name": "Panduan",
      "slug": "panduan"
    },
    "posts": [
      {
        "id": 2137,
        "date_gmt": "2021-07-24T03:51:55",
        "modified_gmt": "2021-07-24T03:59:47",
        "slug": "revisi-panduan-tata-laksana-covid-19-di-indonesia",
        "type": "post",
        "title": {
          "rendered": "Revisi Panduan Tata Laksana COVID-19 di Indonesia"
        },
        "excerpt": {
          "rendered": "<p>Pada tanggal 14 Juli 2021, organisasi profesi dokter di Indonesia, yaitu Perhimpunan Dokter Paru Indonesia (PDPI), Perhimpunan Dokter Spesialis Kardiovaskular Indonesia (PERKI), Perhimpunan Dokter Spesialis Penyakit Dalam Indonesia (PAPDI), Perhimpunan Dokter Spesialis Anestesiologi dan Terapi Intensif (PERDATIN), dan Ikatan Dokter Anak Indonesia (IDAI) merevisi rekomendasi panduan tata laksana COVID-19 di Indonesia. Revisi ini ditujukan untuk [&hellip;]</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1931,
        "date_gmt": "2021-05-31T02:44:59",
        "modified_gmt": "2021-07-03T09:34:24",
        "slug": "cara-isolasi-mandiri-yang-benar",
        "type": "post",
        "title": {
          "rendered": "Cara Isolasi Mandiri yang Benar"
        },
        "excerpt": {
          "rendered": "<p>Tim KawalCOVID-19 berbincang dengan dr. Sayuri Suwandi, SpPD dan dr. Dirga Sakti Rambe, SpPD, untuk menjawab pertanyaan seputar cara isolasi mandiri (isoman) yang benar. Berikut ringkasan dari dua sesi Instagram Live yang bisa dilihat di sini.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1879,
        "date_gmt": "2021-04-15T02:48:10",
        "modified_gmt": "2021-06-25T09:40:22",
        "slug": "satgas-kesehatan-di-sekolah-untuk-apa",
        "type": "post",
        "title": {
          "rendered": "Satgas Kesehatan di Sekolah, Untuk Apa?"
        },
        "excerpt": {
          "rendered": "<p>Per 24 Februari 2021, sudah ada wacana membuka kembali sekolah setelah vaksinasi guru rampung di bulan Juni. Di medio 2021, belum tentu pandemi sudah terkendali, apalagi berakhir. Karenanya, selain harus bersiap untuk mencegah penularan, sekolah juga harus bersiap diri untuk menangani adanya penularan di sekolah, sehingga tidak bingung dan menangani dengan serabutan ketika ada kasus. [&hellip;]</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1818,
        "date_gmt": "2021-04-01T03:05:23",
        "modified_gmt": "2021-07-03T09:03:10",
        "slug": "interaksi-guru-murid-yang-minim-risiko",
        "type": "post",
        "title": {
          "rendered": "Interaksi Guru-Murid Yang Minim Risiko"
        },
        "excerpt": {
          "rendered": "<p>Per 24 Februari 2021, sudah ada wacana bahwa pemerintah akan membuka sekolah setelah vaksinasi guru rampung di bulan Juni. Di medio 2021, belum tentu pandemi terkendali, apalagi berakhir. Karenanya, penting bagi pendidik untuk melengkapi diri dengan cara aman melakukan berinteraksi dengan anak didik.&nbsp; COVID-19 menyebar lewat droplet seperti air liur dan ingus, dua hal yang [&hellip;]</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1645,
        "date_gmt": "2021-02-20T05:39:49",
        "modified_gmt": "2021-07-03T09:03:19",
        "slug": "seri-covid-19-artikel-2-sebelum-buka-sekolah-apa-yang-harus-dikomunikasikan",
        "type": "post",
        "title": {
          "rendered": "Sebelum Buka Sekolah, Apa yang Harus Dikomunikasikan?"
        },
        "excerpt": {
          "rendered": "<p>Kebanyakan sekolah mengerti bahwa ada protokol kesehatan yang harus disiapkan sebelum membuka sekolah di masa pandemi. Tapi dari berbagai input yang disampaikan oleh guru, murid, orang tua maupun administrator sekolah, tidak ada pedoman bagaimana mengkomunikasikan pembukaan sekolah dan perilaku seperti apa yang sekolah minta dari para guru, siswa dan orang tua murid.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1642,
        "date_gmt": "2021-02-20T05:35:56",
        "modified_gmt": "2021-04-07T10:24:29",
        "slug": "infrastruktur-apa-yang-perlu-disiapkan-sekolah-new-normal",
        "type": "post",
        "title": {
          "rendered": "Infrastruktur Apa yang Perlu Disiapkan Sekolah New Normal?"
        },
        "excerpt": {
          "rendered": "<p>Di satu titik, sekolah akan tatap muka lagi. Berapa lama lagi, kita belum tahu. Tapi sekolah perlu menyiapkan infrastruktur new normal mulai sekarang agar ketika nanti sekolah dibuka, semua pihak sudah mengerti apa yang harus dilakukan.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1612,
        "date_gmt": "2021-01-14T02:21:49",
        "modified_gmt": "2021-03-02T04:52:49",
        "slug": "pertimbangan-untuk-langkah-langkah-kesehatan-masyarakat-terkait-sekolah-dalam-konteks-covid-19",
        "type": "post",
        "title": {
          "rendered": "Pertimbangan untuk Langkah-Langkah Kesehatan Masyarakat Terkait Sekolah dalam Konteks COVID-19"
        },
        "excerpt": {
          "rendered": "",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1550,
        "date_gmt": "2020-12-07T14:27:32",
        "modified_gmt": "2021-03-02T04:52:56",
        "slug": "panduan-perawatan-di-rumah-untuk-suspek-dan-positif-covid-19-dengan-gejala-ringan-atau-tanpa-gejala",
        "type": "post",
        "title": {
          "rendered": "Panduan Perawatan di Rumah untuk Suspek dan Positif- COVID-19 dengan Gejala Ringan atau Tanpa Gejala"
        },
        "excerpt": {
          "rendered": "<p>Proses hasil PCR yang lambat, makin banyaknya kasus positif sehingga RS hanya mampu melayani pasien dengan gejala sedang/berat, sehingga isolasi mandiri adalah pilihan buat pasien positif dengan gejala ringan atau untuk suspek COVID-19. Panduan ini diharapkan dapat membantu perawatan mandiri.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1522,
        "date_gmt": "2020-11-01T02:49:47",
        "modified_gmt": "2021-03-02T04:53:06",
        "slug": "isolasi-mandiri-untuk-ibu-menyusui",
        "type": "post",
        "title": {
          "rendered": "Isolasi Mandiri untuk Ibu Menyusui"
        },
        "excerpt": {
          "rendered": "<p>Rangkuman panduan yang harus dilakukan jika ibu menyusui terpapar COVID-19.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1284,
        "date_gmt": "2020-06-15T15:45:44",
        "modified_gmt": "2021-03-02T04:53:16",
        "slug": "mpasi-anak-dalam-kondisi-darurat",
        "type": "post",
        "title": {
          "rendered": "MPASI Anak dalam Kondisi Darurat"
        },
        "excerpt": {
          "rendered": "<p>Menjaga asupan gizi anak penting di tengah pandemi dan mudah dilakukan dengan memberikan Menu 4 Bintang.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1211,
        "date_gmt": "2020-04-30T08:49:24",
        "modified_gmt": "2021-03-02T04:53:24",
        "slug": "menyikapi-kedukaan-dan-kehilangan-saat-pandemi",
        "type": "post",
        "title": {
          "rendered": "Menyikapi Kedukaan dan Kehilangan Saat Pandemi"
        },
        "excerpt": {
          "rendered": "<p>Kehilangan orang tercinta bukan hal yang mudah dihadapi, apalagi di tengah pandemi. Dalam situasi sulit ini, bila memungkinkan, gunakan Internet dan solusi virtual lainnya untuk menjaga silaturahmi dengan keluarga dan teman untuk berbagi rasa kehilangan, serta untuk melaksanakan keinginan terakhir almarhum/almarhumah.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1177,
        "date_gmt": "2020-04-27T07:33:49",
        "modified_gmt": "2021-03-02T04:53:36",
        "slug": "imunisasi-rutin-selama-pandemi-covid-19",
        "type": "post",
        "title": {
          "rendered": "Imunisasi Rutin Selama Pandemi COVID-19"
        },
        "excerpt": {
          "rendered": "<p>Panduan untuk imunisasi rutin selama adanya pandemi COVID-19.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1171,
        "date_gmt": "2020-04-26T13:53:08",
        "modified_gmt": "2021-03-02T04:53:43",
        "slug": "panduan-menerima-dan-memberikan-bantuan-sosial-%ef%bb%bf",
        "type": "post",
        "title": {
          "rendered": "Panduan Menerima dan Memberikan Bantuan Sosial ﻿"
        },
        "excerpt": {
          "rendered": "<p>Saat ini, pembagian bantuan sosial, baik berupa paket sembako, uang, maupun keduanya, sudah banyak berlangsung. Hal ini adalah inisiatif yang baik untuk membantu masyarakat yang membutuhkan di tengah wabah COVID-19. Namun, ada beberapa hal yang perlu dipersiapkan oleh pemberi dan penerima bantuan.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1079,
        "date_gmt": "2020-04-15T05:47:19",
        "modified_gmt": "2021-03-02T04:53:52",
        "slug": "himbauan-untuk-vaksinasi",
        "type": "post",
        "title": {
          "rendered": "Himbauan untuk Vaksinasi"
        },
        "excerpt": {
          "rendered": "<p>Dokumen terkait himbauan untuk pelaksanaan vaksinasi anak selama pandemi COVID-19.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 1063,
        "date_gmt": "2020-04-14T13:15:05",
        "modified_gmt": "2021-03-02T04:54:09",
        "slug": "tips-siaga-bagi-pasangan-yang-menunggu-kelahiran",
        "type": "post",
        "title": {
          "rendered": "Tips Siaga Bagi Pasangan yang Menunggu Kelahiran"
        },
        "excerpt": {
          "rendered": "<p>Lebih dari 200 juta wanita hamil di seluruh dunia berpotensi terinfeksi coronavirus. Beberapa penelitian mulai menguak bahaya virus SARS-CoV-2 ini pada wanita hamil dan bayinya, namun hasilnya&#8230;</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 969,
        "date_gmt": "2020-04-07T10:47:14",
        "modified_gmt": "2021-03-02T04:54:17",
        "slug": "seputar-penghantaran-dan-atau-penerimaan-gofood-gosend",
        "type": "post",
        "title": {
          "rendered": "Panduan Higienis Pengiriman dan/atau Penerimaan Barang/Makanan saat Pandemi"
        },
        "excerpt": {
          "rendered": "<p>Panduan apa yang sebaiknya dilakukan saat menerima atau mengirim barang melalui ojek online.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 962,
        "date_gmt": "2020-04-07T04:31:11",
        "modified_gmt": "2021-03-02T04:54:24",
        "slug": "rt-dan-desa-mandiri-lawan-korona",
        "type": "post",
        "title": {
          "rendered": "RT dan Desa Mandiri Lawan Korona"
        },
        "excerpt": {
          "rendered": "<p>Simak tulisan berikut ini untuk mengetahui panduan bagi RT dan desa dalam melawan koronavirus.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 960,
        "date_gmt": "2020-04-06T14:30:12",
        "modified_gmt": "2021-03-02T04:54:32",
        "slug": "prosedur-mendirikan-pusat-karantina-di-luar-fasilitas-kesehatan",
        "type": "post",
        "title": {
          "rendered": "Prosedur Mendirikan Pusat Karantina Di Luar Fasilitas Kesehatan"
        },
        "excerpt": {
          "rendered": "<p>Saat jumlah kasus positif COVID-19 meningkat sangat drastis melebihi kemampuan rumah sakit untuk merawat penderita, maka pemerintah setempat&#8230;.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 850,
        "date_gmt": "2020-03-31T09:59:48",
        "modified_gmt": "2021-03-02T04:54:40",
        "slug": "panduan-membuat-masker-kain-sendiri",
        "type": "post",
        "title": {
          "rendered": "Panduan Membuat Masker Kain Sendiri"
        },
        "excerpt": {
          "rendered": "<p>Saat ini, masker sulit ditemukan. Kalau pun tersedia, masker bedah lebih dibutuhkan oleh tenaga medis daripada masyarakat umum. Tapi jangan khawatir, untuk melindungi diri sendiri dan orang di sekitar, kita bisa, kok, membuat masker sendiri untuk kita dan keluarga. Simak tulisan berikut ini. </p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 846,
        "date_gmt": "2020-03-31T05:20:53",
        "modified_gmt": "2021-03-02T04:54:49",
        "slug": "panduan-menjaga-diri-dan-orang-lain-bila-terlanjur-mudik",
        "type": "post",
        "title": {
          "rendered": "Panduan Menjaga Diri dan Orang Lain Bila Terlanjur Mudik"
        },
        "excerpt": {
          "rendered": "<p>Bepergian ke luar kota, apalagi ke luar negeri, sangat tidak dianjurkan di tengah wabah. Namun, jika terlanjur mudik, ada hal-hal yang perlu dilakukan untuk menjaga diri dan orang di sekitar kita.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 715,
        "date_gmt": "2020-03-21T18:12:47",
        "modified_gmt": "2021-03-02T04:54:57",
        "slug": "panduan-penanganan-jenazah-terduga-kemungkinan-terkonfirmasi-covid-19",
        "type": "post",
        "title": {
          "rendered": "Panduan Penanganan Jenazah Terduga / Kemungkinan / Terkonfirmasi COVID-19"
        },
        "excerpt": {
          "rendered": "<p>Ikuti panduan ini untuk penanganan jenazah Terduga atau Positif COVID-19</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 563,
        "date_gmt": "2020-03-17T09:27:09",
        "modified_gmt": "2021-03-02T04:55:06",
        "slug": "panduan-naik-ojek-motor-mobil-untuk-pengemudi-dan-penumpang",
        "type": "post",
        "title": {
          "rendered": "Panduan Naik Ojek Motor/Mobil untuk Pengemudi dan Penumpang"
        },
        "excerpt": {
          "rendered": "<p>Ikuti panduan ini yuk untuk berkendara aman dengan Ojek Motor/Mobil di masa pandemi COVID-19 ini.</p>\n",
          "protected": false
        },
        "author": 2
      }
    ],
    "authors": {
      "2": {
        "id": 2,
        "name": "Kawal COVID-19",
        "description": "Tim administrator situs KawalCOVID19.id",
        "slug": "admin",
        "avatar_urls": {
          "24": "http://0.gravatar.com/avatar/662a7ed69e971f6fb58f2c0aff371674?s=24&d=retro&r=g",
          "48": "http://0.gravatar.com/avatar/662a7ed69e971f6fb58f2c0aff371674?s=48&d=retro&r=g",
          "96": "http://0.gravatar.com/avatar/662a7ed69e971f6fb58f2c0aff371674?s=96&d=retro&r=g"
        },
        "_links": {
          "self": [
            {
              "href": "http://18.141.204.243/index.php?rest_route=/wp/v2/users/2"
            }
          ],
          "collection": [
            {
              "href": "http://18.141.204.243/index.php?rest_route=/wp/v2/users"
            }
          ]
        }
      }
    }
  },
  "__N_SSG": true
}
