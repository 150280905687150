{
  "pageProps": {
    "category": {
      "id": 43,
      "count": 4,
      "description": "List dan daftar yang bermanfaat",
      "name": "Check-List",
      "slug": "checklist"
    },
    "posts": [
      {
        "id": 1485,
        "date_gmt": "2020-10-01T14:19:43",
        "modified_gmt": "2020-10-01T14:20:17",
        "slug": "bahan-bahan-memperkenalkan-covid-19-ke-anak-usia-dini",
        "type": "post",
        "title": {
          "rendered": "Bahan-bahan Memperkenalkan Covid-19 ke Anak Usia Dini"
        },
        "excerpt": {
          "rendered": "<p>Memperkenalkan COVID-19 ke anak-anak usia dini (3-6 tahun) sangat penting. Di bawah ini adalah beberapa resources berupa link, video, infografika, printables yang bisa diprint untuk semua. 3M&nbsp; Apa itu Covid :&nbsp; eBook :&nbsp; Cerita si Korona karangan Watiek Ideo https://online.fliphtml5.com/nwyyb/grdh/#p=1&nbsp; Gara-gara si Korona karangan Watiek Ideo https://online.fliphtml5.com/nwyyb/csin/#p=1&nbsp; Perjalanan si Korona karangan Watiek Ideo https://online.fliphtml5.com/nwyyb/lnjo/#p=10&nbsp; Video [&hellip;]</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 817,
        "date_gmt": "2020-03-29T03:25:00",
        "modified_gmt": "2021-07-03T09:03:48",
        "slug": "daftar-layanan-konseling-online-se-indonesia",
        "type": "post",
        "title": {
          "rendered": "Daftar Layanan Konseling Online Se-Indonesia"
        },
        "excerpt": {
          "rendered": "<p>Kami mengumpulkan berbagai poster informasi tentang layanan konseling online yang tersebar di berbagai wilayah.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 644,
        "date_gmt": "2020-03-21T03:51:30",
        "modified_gmt": "2020-04-06T10:04:12",
        "slug": "daftar-gereja-dengan-ibadah-online",
        "type": "post",
        "title": {
          "rendered": "Daftar Gereja dengan Ibadah Online"
        },
        "excerpt": {
          "rendered": "<p>Kami mengajak semua umat Nasrani ikut berpartisipasi dalam mencegah penyebaran COVID-19 dengan melakukan #JagaJarakDulu dan #DiamDiRumah.</p>\n",
          "protected": false
        },
        "author": 2
      },
      {
        "id": 492,
        "date_gmt": "2020-03-18T13:25:00",
        "modified_gmt": "2020-04-03T14:05:04",
        "slug": "jadwal-harian-covid-19-usia-sd",
        "type": "post",
        "title": {
          "rendered": "Jadwal Harian COVID-19 Usia SD"
        },
        "excerpt": {
          "rendered": "<p>Kegiatan harian yang dapat dilakukan anak untuk usia SD.</p>\n",
          "protected": false
        },
        "author": 2
      }
    ],
    "authors": {
      "2": {
        "id": 2,
        "name": "Kawal COVID-19",
        "description": "Tim administrator situs KawalCOVID19.id",
        "slug": "admin",
        "avatar_urls": {
          "24": "http://0.gravatar.com/avatar/662a7ed69e971f6fb58f2c0aff371674?s=24&d=retro&r=g",
          "48": "http://0.gravatar.com/avatar/662a7ed69e971f6fb58f2c0aff371674?s=48&d=retro&r=g",
          "96": "http://0.gravatar.com/avatar/662a7ed69e971f6fb58f2c0aff371674?s=96&d=retro&r=g"
        },
        "_links": {
          "self": [
            {
              "href": "http://18.141.204.243/index.php?rest_route=/wp/v2/users/2"
            }
          ],
          "collection": [
            {
              "href": "http://18.141.204.243/index.php?rest_route=/wp/v2/users"
            }
          ]
        }
      }
    }
  },
  "__N_SSG": true
}
